/* eslint-disable camelcase */
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { alpha } from '@mui/material/styles';
import Flag from 'react-world-flags';
import { motion } from 'framer-motion';
import {
  Box, Divider, Typography, MenuItem, IconButton, Stack, Tooltip,
} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import MenuPopover from '../../MenuPopover/MenuPopover';
import Avatar from '../../Avatar';
import check from '../../../assets/dashboard/selected_store_check.svg';
import createAvatar from '../../../utils/createAvatar';
import { logout } from '../../../redux/actions/authAction';
// import { SwitchStyled } from '../../StepsCheckout/stepscheckout.styled';
// import { getOrders } from '../../../redux/actions/ordersAction';
import { ImageBox, StoreSelection } from '../../Login/Login.styled';
import { availableCountries } from '../../../utils/countries';

function AccountPopover() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const userRole = useSelector((state) => state.role);
  const selectedStore = useSelector((state) => state.merchant.selectedStore);
  const stores = useSelector((state) => state.merchant.stores);
  const { first_name, last_name, email } = useSelector((state) => state.user);
  const { t } = useTranslation(['header']);
  const [open, setOpen] = useState(null);
  const fullName = (first_name && last_name) ? `${first_name} ${last_name}` : email;

  // const handleRoleChange = () => {
  //   dispatch({
  //     type: 'SET_MESSAGE',
  //     payload: { text: t('switch_role_msg'), kind: 'success' },
  //   });
  //   dispatch({
  //     type: 'SWITCH_ROLE',
  //   });
  //   if (userRole === 'Merchant') {
  //     dispatch(getOrders());
  //   } else if (userRole === 'Shopper' && stores.length > 0) {
  //     dispatch(getOrders(selectedStore?.id_store));
  //   }
  // };

  useEffect(() => {
    if (selectedStore?.status === 'draft') {
      navigate('/fillout');
    }
  }, [selectedStore]);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = () => {
    dispatch(logout(navigate));
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.5),
            },
          }),
        }}
      >
        <Avatar alt={fullName} color={createAvatar(fullName).color}>
          {createAvatar(fullName).name}
        </Avatar>
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          width: '18rem',
          maxHeight: '80vh',
          overflowY: 'auto',
          overflowX: 'hidden',
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
            '&.Mui-selected': {
              backgroundColor: 'action.selected',
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {fullName || ''}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {email}
          </Typography>
        </Box>
        <Divider />
        {/* {merchant && (
          <>
            <Divider />
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              my: 1.5,
              px: 2.5,
            }}
            >
              <Typography variant="subtitle2" noWrap>
                {t('switch_roles')}
              </Typography>
              <SwitchStyled
                checked={userRole === 'Merchant'}
                size="small"
                onChange={() => handleRoleChange()}
              />
            </Box>
          </>
        )} */}
        {stores?.length > 1 ? (
          stores.map((store) => {
            const truncatedName = store?.store_name?.length > 20
              ? `${store.store_name.substring(0, 20)}...`
              : store?.store_name;

            return (
              <Tooltip title={store?.store_name} key={store?.id_store}>
                <StoreSelection
                  onClick={() => dispatch({
                    type: 'SET_SELECTED_STORE',
                    payload: store,
                  })}
                  sx={{ padding: '1rem', gap: '0.5rem' }}
                >
                  <ImageBox sx={{ position: 'relative' }}>
                    <img
                      src={store?.logo_image
                        || 'https://wava-assets.s3.amazonaws.com/wava-dashboard/section-settings/icon-storefront.svg'}
                      alt="store"
                    />
                    {store?.country_name && (
                      <Box sx={{
                        position: 'absolute',
                        bottom: -8,
                        right: -8,
                        width: '20px',
                        height: '15px',
                        borderRadius: '2px',
                        overflow: 'hidden',
                        boxShadow: '0 0 2px rgba(0,0,0,0.2)',
                      }}
                      >
                        <Flag
                          code={availableCountries.find((c) => c.name === store.country_name)?.isoCode || ''}
                          fallback=""
                        />
                      </Box>
                    )}
                  </ImageBox>
                  <Stack width="100%" spacing={0.5}>
                    <Typography variant="body2" fontWeight="bold">
                      {truncatedName}
                    </Typography>
                  </Stack>
                  <motion.div
                    key={selectedStore?.id_store}
                    initial={{ transform: 'scale(0)' }}
                    animate={{ transform: 'scale(1)' }}
                    exit={{ transform: 'scale(0)' }}
                  >
                    {selectedStore?.id_store === store?.id_store ? <img style={{ width: '40px' }} src={check} alt="✅" /> : null}
                  </motion.div>
                </StoreSelection>
              </Tooltip>
            );
          })
        ) : null}
        {stores?.length > 1 && <Divider />}
        <MenuItem sx={{ m: 1, gap: 1 }} onClick={() => navigate('/onboard')}>
          <AddBusinessIcon />
          {t('create_store')}
        </MenuItem>
        <MenuItem sx={{ m: 1, gap: 1 }} onClick={handleLogout}>
          <LogoutIcon />
          {t('logout')}
        </MenuItem>
      </MenuPopover>
    </>
  );
}

export default AccountPopover;
