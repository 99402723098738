import React, { useEffect, useState } from 'react';
import Flag from 'react-world-flags';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useNavigate } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import VisibilityIcon from '@mui/icons-material/Visibility';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Paper from '@mui/material/Paper';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Chip, styled, TextField, Grid, Button, Tooltip, IconButton, ListItemIcon } from '@mui/material';
import { useTranslation } from 'react-i18next';
import EnhancedTableHead from './EnhancedTableHead';
import TransitionComponent from '../../Logged/TransitionComponent';
import { getAdminStores } from '../../../redux/actions/adminActions';
import { availableCountries } from '../../../utils/countries';

const TableCellStyled = styled(TableCell)(({ theme }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  [theme.breakpoints.down('md')]: {
    padding: '8px',
  },
}));

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const truncateText = (text, maxLength) => {
  return text?.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
};

function StoresList() {
  const dispatch = useDispatch();
  const { t } = useTranslation(['stores']);
  const stores = useSelector((state) => state.admin.stores);
  const [sortOrder, setSortOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('store_name');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([]);
  const [filters, setFilters] = useState({
    id_store: '',
    store_name: '',
    store_nit: '',
    store_url: '',
    status: '',
  });
  const [statusCounts, setStatusCounts] = useState({
    all: 0,
    active: 0,
    draft: 0,
  });
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedStore, setSelectedStore] = useState(null);

  const createData = (id, logo, url, nit, country, name, status) => {
    return {
      id,
      logo,
      url,
      nit,
      country,
      name,
      status,
    };
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleStatusFilter = (status) => {
    setFilters((prev) => ({
      ...prev,
      status: status || '',
    }));
  };

  const getFilteredRows = (rowsToFilter) => {
    return rowsToFilter.filter((row) => {
      const matchesId = !filters.id_store || row.id.toString().includes(filters.id_store);
      const matchesName = !filters.store_name || row.name.toLowerCase().includes(filters.store_name.toLowerCase());
      const matchesNit = !filters.store_nit || (row.nit && row.nit.includes(filters.store_nit));
      const matchesUrl = !filters.store_url || row.url.toLowerCase().includes(filters.store_url.toLowerCase());
      const matchesStatus = !filters.status || row.status === filters.status;

      return matchesId && matchesName && matchesNit && matchesUrl && matchesStatus;
    });
  };

  const clearFilters = () => {
    setFilters({
      id_store: '',
      store_name: '',
      store_nit: '',
      store_url: '',
      status: '',
    });
  };

  useEffect(() => {
    dispatch(getAdminStores());
  }, [dispatch]);

  useEffect(() => {
    if (stores?.length > 0) {
      const newRows = stores.map((store) =>
        createData(store.id_store, store.logo_image, store.store_url, store.store_nit, store.country_name, store.store_name, store.status),
      );
      setRows(newRows);

      setStatusCounts({
        all: stores.length,
        active: stores.filter((store) => store.status === 'active').length,
        draft: stores.filter((store) => store.status === 'draft').length,
      });
    }
  }, [stores]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && sortOrder === 'asc';
    setSortOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(() => {
    const filteredRows = getFilteredRows(rows);
    return stableSort(filteredRows, getComparator(sortOrder, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [sortOrder, orderBy, page, rowsPerPage, rows, filters]);

  const handleMenuOpen = (event, store) => {
    setAnchorEl(event.currentTarget);
    setSelectedStore(store);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedStore(null);
  };

  const handleViewDetails = () => {
    navigate(`/admin/stores/${selectedStore.id}`);
    handleMenuClose();
  };

  return (
    <TransitionComponent>
      <Box sx={{ width: '100%', overflow: 'auto' }}>
        <Paper
          sx={{
            width: '100%',
            mb: 2,
            p: 2,
            border: '1px solid #919EAB3D',
          }}
        >
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Chip
              label={`${t('All')} (${statusCounts.all})`}
              onClick={() => handleStatusFilter('')}
              variant={!filters.status ? 'filled' : 'outlined'}
            />
            <Chip
              label={`${t('Active')} (${statusCounts.active})`}
              onClick={() => handleStatusFilter('active')}
              color="success"
              variant={filters.status === 'active' ? 'filled' : 'outlined'}
            />
            <Chip
              label={`${t('Draft')} (${statusCounts.draft})`}
              onClick={() => handleStatusFilter('draft')}
              color="info"
              variant={filters.status === 'draft' ? 'filled' : 'outlined'}
            />
          </Box>
        </Paper>

        <Paper
          sx={{
            width: '100%',
            mb: 2,
            p: 2,
            border: '1px solid #919EAB3D',
          }}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6} md={2.4}>
              <TextField fullWidth label={t('Store ID')} name="id_store" value={filters.id_store} onChange={handleFilterChange} size="small" />
            </Grid>
            <Grid item xs={12} sm={6} md={2.4}>
              <TextField fullWidth label={t('Store Name')} name="store_name" value={filters.store_name} onChange={handleFilterChange} size="small" />
            </Grid>
            <Grid item xs={12} sm={6} md={2.4}>
              <TextField fullWidth label={t('Store NIT')} name="store_nit" value={filters.store_nit} onChange={handleFilterChange} size="small" />
            </Grid>
            <Grid item xs={12} sm={6} md={2.4}>
              <TextField fullWidth label={t('Store URL')} name="store_url" value={filters.store_url} onChange={handleFilterChange} size="small" />
            </Grid>
            <Grid item xs={12} sm={6} md={2.4}>
              <Button variant="outlined" onClick={clearFilters} fullWidth>
                {t('Clear')}
              </Button>
            </Grid>
          </Grid>
        </Paper>

        <Paper
          sx={{
            width: '100%',
            mb: 2,
            border: '1px solid #919EAB3D',
          }}
        >
          <TableContainer>
            <Table
              sx={{
                minWidth: { xs: '100%', sm: 750 },
              }}
              aria-labelledby="tableTitle"
              size="medium"
            >
              <EnhancedTableHead order={sortOrder} orderBy={orderBy} onRequestSort={handleRequestSort} />
              <TableBody>
                {rows.length > 0 ? (
                  visibleRows.map((row) => {
                    return (
                      <TableRow hover tabIndex={-1} key={row.id}>
                        <TableCellStyled align="left">{row.id}</TableCellStyled>
                        <TableCellStyled align="left">
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: { xs: 1, sm: 2 },
                            }}
                          >
                            <Avatar
                              src={row.logo}
                              alt={row.name}
                              sx={{
                                width: { xs: 24, sm: 32 },
                                height: { xs: 24, sm: 32 },
                              }}
                            />
                            <Tooltip title={row.name}>
                              <TableCell sx={{ p: 0 }}>{truncateText(row.name, 25)}</TableCell>
                            </Tooltip>
                          </Box>
                        </TableCellStyled>
                        <TableCellStyled align="left">{row.nit || '-'}</TableCellStyled>
                        <TableCellStyled align="left">
                          <Tooltip title={row.url}>
                            <TableCell sx={{ p: 0 }}>{truncateText(row.url, 35)}</TableCell>
                          </Tooltip>
                        </TableCellStyled>
                        <TableCellStyled align="left">
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: { xs: 0.5, sm: 1 },
                            }}
                          >
                            <Box
                              sx={{
                                width: { xs: '16px', sm: '20px' },
                                height: { xs: '12px', sm: '15px' },
                                borderRadius: '2px',
                                overflow: 'hidden',
                                boxShadow: '0 0 2px rgba(0,0,0,0.2)',
                              }}
                            >
                              <Flag code={availableCountries.find((c) => c.name === row.country)?.isoCode || ''} fallback="" />
                            </Box>
                            {row.country || '-'}
                          </Box>
                        </TableCellStyled>
                        <TableCell align="right">
                          <Chip size="small" sx={{ color: '#fff' }} label={row.status} color={row.status === 'active' ? 'success' : 'info'} />
                        </TableCell>
                        <TableCell align="right">
                          <IconButton onClick={(e) => handleMenuOpen(e, row)}>
                            <MoreVertIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      No stores found
                    </TableCell>
                  </TableRow>
                )}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25]}
            component="div"
            sx={{ marginRight: '1rem' }}
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleViewDetails}>
          <ListItemIcon>
            <VisibilityIcon fontSize="small" />
          </ListItemIcon>
          {t('View Details')}
        </MenuItem>
      </Menu>
    </TransitionComponent>
  );
}

export default StoresList;
