import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Divider, IconButton, Stack, Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { motion } from 'framer-motion';
import wavaLogo from '../../assets/dashboard/logo_wava.svg';
import step1 from '../../assets/login/step1.webp';
import step2 from '../../assets/login/step2.webp';
import step3 from '../../assets/login/step3.webp';
import step4 from '../../assets/login/step4.webp';
import {
  WavaInfoSection,
  WavaInfoWrapper,
  LoginContainer,
  LoginFooter,
  LoginSection,
  SectionsContainer,
  TextStack,
  WavaInfoStepper,
  WavaInfoImage,
  StepperStack,
  InfoStepCircle,
} from './Login.styled';
import {
  LoggedNavbar, WavaLogo, LogoStack,
} from '../Logged/logged.styled';
import NavPopover from '../Logged/NavPopover/NavPopover';
import AccountPopover from '../Logged/AccountPopover/AccountPopover';

function LoginFrame({ children }) {
  const { t } = useTranslation(['login']);
  const location = useLocation();
  const { isAuthenticated } = useSelector((state) => state.user);
  const imgArr = [step1, step2, step3, step4];
  const [infoStep, setInfoStep] = useState(1);

  const handleStepper = (action) => {
    if (infoStep < 4 && action === 'up') {
      setInfoStep(infoStep + 1);
    }
    if (infoStep > 1 && action === 'down') {
      setInfoStep(infoStep - 1);
    }
  };

  return (
    <LoginContainer>
      {isAuthenticated && (
        <LoggedNavbar>
          <LogoStack>
            <NavPopover />
            <WavaLogo src={wavaLogo} alt="Wava" />
          </LogoStack>
          <AccountPopover />
        </LoggedNavbar>
      )}
      <SectionsContainer>
        {children || (
          <>
            <WavaInfoSection>
              <WavaInfoWrapper>
                <Stack
                  sx={{
                    maxWidth: '40rem',
                    textAlign: 'center',
                    gap: '1rem',
                    paddingLeft: '1rem',
                    paddingRight: '1rem',
                  }}
                >
                  <Typography variant="h3" fontFamily="Clash Grotesk Variable">
                    {t('carousel-title')}
                  </Typography>
                  <Stack direction="row" alignItems="center" gap={1} justifyContent="center">
                    <InfoStepCircle>
                      <Typography variant="h4" fontFamily="Clash Grotesk Variable">
                        {infoStep}
                      </Typography>
                    </InfoStepCircle>
                    <Typography variant="body1" sx={{ maxWidth: '436px' }}>
                      {t(`carousel-desc-${infoStep}`)}
                    </Typography>
                  </Stack>
                </Stack>
                <WavaInfoStepper>
                  <StepperStack>
                    <IconButton disabled={infoStep === 1} onClick={() => handleStepper('down')} aria-label="Previous Image">
                      <ChevronLeft />
                    </IconButton>
                    <motion.div
                      key={infoStep}
                      transition={{ type: 'tween', duration: 0.5 }}
                      initial={{ opacity: 0, translateX: '15px' }}
                      animate={{ opacity: 1, translateX: '0px' }}
                      exit={{ opacity: 0, translateX: '0px' }}
                    >
                      {imgArr.map((img, index) => (
                        <WavaInfoImage key={`k${img}`} active={index === infoStep - 1} width="30rem" height="28rem" src={img} alt="wava features" />
                      ))}
                    </motion.div>
                    <IconButton disabled={infoStep === 4} onClick={() => handleStepper('up')} aria-label="Next Image">
                      <ChevronRight />
                    </IconButton>
                  </StepperStack>
                </WavaInfoStepper>
              </WavaInfoWrapper>
            </WavaInfoSection>
            <LoginSection>
              <>
                <motion.div
                  style={location?.pathname === '/fillout' ? { display: 'flex', width: '100%', height: '100%' } : { height: '100%' }}
                  key={location?.pathname}
                  transition={{ type: 'tween', duration: 0.5 }}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  <Outlet />
                </motion.div>
                <LoginFooter sx={{ display: location?.pathname !== '/fillout' ? 'flex' : 'none' }}>
                  <Divider orientation="horizontal" sx={{ width: '100%', alignSelf: 'end' }} />
                  <TextStack sx={{ textAlign: { xs: 'center', sm: 'flex-start' } }}>
                    <Typography variant="body2">{t('footer-text')}</Typography>
                    <Typography
                      sx={{
                        textDecoration: 'underline',
                        cursor: 'pointer',
                        width: { xs: '100%', lg: 'min-content' },
                      }}
                      variant="body2"
                      onClick={() => window.open('https://wava.co/contacto/', '_blank')}
                    >
                      {t('footer-cta')}
                    </Typography>
                  </TextStack>
                </LoginFooter>
              </>
            </LoginSection>
          </>
        )}
      </SectionsContainer>
    </LoginContainer>
  );
}

export default LoginFrame;
