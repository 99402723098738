import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  CircularProgress, IconButton, Stack, TextField, Typography, useMediaQuery,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { HeadingsContainer, MainContainer, PriceSpan } from './dashboard.styled';
import { fCurrency } from '../../utils/formatNumber';
import { fDateCalendar, fDateShort } from '../../utils/formatTime';
import DashCarousel from './DashCarousel';
import DashboardCharts from './DashboardCharts/DashboardCharts';
// import DashMoreSold from './DashboardCharts/DashMoreSold';
import { getDashboardData } from '../../redux/actions/userActions';
import { TextInputStyled } from '../StepsCheckout/stepscheckout.styled';
import TransitionComponent from '../Logged/TransitionComponent';

function Dashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:780px)');
  const isAdmin = useSelector((state) => state.user?.is_admin);
  const selectedStore = useSelector((state) => state.merchant.selectedStore);
  const storeName = selectedStore?.store_name;
  const stores = useSelector((state) => state.merchant.stores);
  const loadingDashboard = useSelector((state) => state.dashboard.loadingDashboard);
  const dashboardData = useSelector((state) => state.dashboard);
  const { t } = useTranslation(['dashboard']);
  const [storeToSearch, setStoreToSearch] = useState('');

  const currentDate = new Date();
  const lastWeekDate = new Date(currentDate.getTime()
    - 7 * 24 * 60 * 60 * 1000);

  const [fromDate, setFromDate] = useState(fDateShort(lastWeekDate) || null);
  const [toDate, setToDate] = useState(fDateShort(currentDate) || null);

  const handleDashboardByAdmin = () => {
    dispatch(getDashboardData(storeToSearch));
    setFromDate(null);
    setToDate(null);
  };

  const handleCustomDateRange = () => {
    if (storeToSearch !== '') {
      dispatch(getDashboardData(storeToSearch, {
        from: fDateCalendar(fromDate),
        to: fDateCalendar(toDate),
      }));
    } else {
      dispatch(getDashboardData(selectedStore?.id_store, {
        from: fDateCalendar(fromDate),
        to: fDateCalendar(toDate),
      }));
    }
  };

  useEffect(() => {
    if (stores.length > 0) {
      dispatch(getDashboardData(selectedStore?.id_store));
    } else {
      navigate('/');
    }
  }, [stores.length, selectedStore]);

  return (
    <TransitionComponent>
      <MainContainer>
        <HeadingsContainer>
          <Stack
            sx={{
              flexDirection: isMobile ? 'column' : (isAdmin ? 'row-reverse' : 'row'),
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <TextInputStyled
              sx={isAdmin
                ? {
                  display: 'flex',
                  alignSelf: 'center',
                  mb: isMobile ? 2 : 0,
                }
                : { display: 'none' }}
              type="number"
              size="small"
              label={t('dashboard-admin-input-label')}
              placeholder={t('dashboard-admin-input-ph')}
              value={storeToSearch}
              onChange={(e) => setStoreToSearch(e.target.value)}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: (
                  <IconButton
                    size="small"
                    disabled={storeToSearch === ''}
                    onClick={() => handleDashboardByAdmin()}
                  >
                    <SearchIcon />
                  </IconButton>
                ),
              }}
            />
            <Stack gap={2} direction="row">
              <Typography variant="h5">
                {t('dashboard-title', { storeName })}
              </Typography>
              {(loadingDashboard && isAdmin === 1) && <CircularProgress />}
            </Stack>
          </Stack>
          {dashboardData?.total_revenue
            ? (
              <Typography variant="h3">
                {t('total-income')}
                <PriceSpan>
                  {`${fCurrency(dashboardData?.total_revenue)}.-`}
                </PriceSpan>
              </Typography>
            )
            : (
              <Typography variant="h3">
                {t('no-total-income')}
              </Typography>
            )}
          <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2} mt={2}>
            <DatePicker
              label={t('dashboard-filter-from')}
              value={fromDate}
              onChange={(e) => setFromDate(fDateShort(e))}
              renderInput={(props) => <TextField {...props} variant="outlined" />}
            />
            <DatePicker
              label={t('dashboard-filter-to')}
              value={toDate}
              onChange={(e) => setToDate(fDateShort(e))}
              renderInput={(props) => <TextField {...props} variant="outlined" />}
            />
            {loadingDashboard
              ? <CircularProgress />
              : (
                <Button
                  size="large"
                  variant="contained"
                  sx={{ borderRadius: '4rem', marginLeft: 1 }}
                  disabled={!fromDate || !toDate}
                  onClick={() => handleCustomDateRange(fromDate)}
                >
                  {t('dashboard-filter-cta')}
                </Button>
              )}
          </Stack>
        </HeadingsContainer>
        <DashCarousel />
        <DashboardCharts />
        {/* <DashMoreSold /> */}
      </MainContainer>
    </TransitionComponent>
  );
}

export default Dashboard;
