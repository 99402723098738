import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Paper,
  Typography,
  Grid,
  Chip,
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Card,
  CardHeader,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  IconButton,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Flag from 'react-world-flags';
import StorefrontIcon from '@mui/icons-material/Storefront';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import GroupIcon from '@mui/icons-material/Group';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import SaveIcon from '@mui/icons-material/Save';
import PhoneIcon from '@mui/icons-material/Phone';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ShareIcon from '@mui/icons-material/Share';
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import { availableCountries } from '../../../utils/countries';
import { fDateTime } from '../../../utils/formatTime';
import TransitionComponent from '../../Logged/TransitionComponent';
import { getAdminStoreDetails, searchUserByEmail, addStoreMerchant, updateStore } from '../../../redux/actions/adminActions';
import DashCarousel from '../../Dashboard/DashCarousel';
import { getDashboardData } from '../../../redux/actions/userActions';

function StoreDetails() {
  const { storeId } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation(['common', 'admin']);
  const storeDetails = useSelector((state) => state.admin.storeDetails);
  const loading = useSelector((state) => state.admin.loading);
  const [openDialog, setOpenDialog] = useState(false);
  const [searchEmail, setSearchEmail] = useState('');
  const foundUsers = useSelector((state) => state.admin.userSearchResults);
  const [anchorEl, setAnchorEl] = useState(null);
  const [payoutAccount, setPayoutAccount] = useState({
    account_name: '',
    account_number: '',
  });
  const [isPayoutEdited, setIsPayoutEdited] = useState(false);
  const [payoutFrequencyDays, setPayoutFrequencyDays] = useState('');
  const [isFrequencyEdited, setIsFrequencyEdited] = useState(false);
  const [openIntegrationDialog, setOpenIntegrationDialog] = useState(false);
  const [selectedIntegration, setSelectedIntegration] = useState(null);

  useEffect(() => {
    if (storeId) {
      dispatch(getAdminStoreDetails(storeId));
      dispatch(getDashboardData(storeId));
    }
  }, [storeId, dispatch]);

  useEffect(() => {
    if (storeDetails?.payoutAccount) {
      setPayoutAccount(storeDetails.payoutAccount);
      setPayoutFrequencyDays(storeDetails.payout_frequency_days || '');
    }
  }, [storeDetails]);

  const getLogoUrl = (integrationType) => {
    switch (integrationType?.toLowerCase()) {
      case 'tiendanube':
        return 'https://wava-assets.s3.amazonaws.com/wava-dashboard/section-settings/tiendanube-logo.svg';
      case 'woocommerce':
        return 'https://wava-assets.s3.amazonaws.com/wava-dashboard/section-settings/woocommerce-logo.svg';
      case 'api':
        return 'https://wava-assets.s3.amazonaws.com/wava-dashboard/section-settings/terminal-logo.svg';
      default:
        return null; // This will use the current generic icon
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return '-';
    try {
      const date = new Date(dateString);
      return fDateTime(date, { timeZone: 'local' });
    } catch (error) {
      return '-';
    }
  };

  const handleSearchUser = async () => {
    dispatch(searchUserByEmail(searchEmail));
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handlePayoutChange = (field) => (event) => {
    if (field === 'account_name' || (field === 'account_number' && !event.target.value.startsWith('***')) || event.target.value.length > 0) {
      setPayoutAccount((prev) => ({
        ...prev,
        [field]: event.target.value,
      }));
      setIsPayoutEdited(true);
    }
  };

  const handlePayoutSubmit = async () => {
    const payload = {
      payout: payoutAccount,
      payout_frequency_days: payoutFrequencyDays,
    };
    const success = await dispatch(updateStore(storeId, payload));
    if (success) {
      setIsPayoutEdited(false);
      setIsFrequencyEdited(false);
      if (success?.message) dispatch({ type: 'SET_MESSAGE', payload: { text: success?.message, kind: 'success' } });
    }
  };

  const handleStoreAction = async (action) => {
    dispatch(updateStore(storeId, { status: action }));
    handleMenuClose();
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSearchEmail('');
    dispatch(getAdminStoreDetails(storeId));
  };

  const handleAddMerchant = async (userId) => {
    await dispatch(addStoreMerchant(storeId, userId));
  };

  const handleOpenIntegrationDetails = (integration) => {
    setSelectedIntegration(integration);
    setOpenIntegrationDialog(true);
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '400px' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!storeDetails) {
    return null;
  }

  return (
    <TransitionComponent>
      <Box sx={{ width: '100%', overflow: 'auto', p: { xs: 2, sm: 3 } }}>
        <Grid container spacing={3}>
          {/* Store Overview Card */}
          <Grid item xs={12} md={8}>
            <Paper sx={{ p: { xs: 2, sm: 3 }, height: '100%', border: '1px solid #919EAB3D' }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                    <Avatar src={storeDetails.logo_image} alt={storeDetails.store_name} sx={{ width: 64, height: 64 }} />
                    <Box>
                      <Typography variant="h5" component="h1">
                        {storeDetails.store_name}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        ID:
                        {storeDetails.id_store}
                      </Typography>
                    </Box>
                    <Box sx={{ ml: 'auto', display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Chip
                        label={storeDetails.status}
                        color={
                          storeDetails.status === 'active'
                            ? 'success'
                            : storeDetails.status === 'inactive'
                              ? 'error'
                              : storeDetails.status === 'draft'
                                ? 'info'
                                : 'default'
                        }
                      />
                      <IconButton onClick={handleMenuClick} size="small">
                        <KeyboardArrowDownIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12} md={6}>
                  <List>
                    <ListItem>
                      <ListItemIcon>
                        <StorefrontIcon />
                      </ListItemIcon>
                      <ListItemText primary="Store URL" secondary={storeDetails.store_url} />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <Box
                          sx={{
                            width: '24px',
                            height: '18px',
                            borderRadius: '2px',
                            overflow: 'hidden',
                            boxShadow: '0 0 2px rgba(0,0,0,0.2)',
                          }}
                        >
                          <Flag code={availableCountries.find((c) => c.name === storeDetails.country_name)?.isoCode || ''} fallback="" />
                        </Box>
                      </ListItemIcon>
                      <ListItemText primary="Country" secondary={storeDetails.country_name} />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <PhoneIcon />
                      </ListItemIcon>
                      <ListItemText primary="Phone Number" secondary={storeDetails.phone_number || '-'} />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <ShareIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary="Referral Link"
                        secondary={(
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
                              {storeDetails.referral_link || '-'}
                            </Typography>
                            {storeDetails.referral_link && (
                              <IconButton
                                size="small"
                                onClick={() => {
                                  navigator.clipboard.writeText(storeDetails.referral_link);
                                  dispatch({
                                    type: 'SET_MESSAGE',
                                    payload: {
                                      text: t('common:copiedToClipboard'),
                                      kind: 'success',
                                    },
                                  });
                                }}
                              >
                                <ContentCopyIcon fontSize="small" />
                              </IconButton>
                            )}
                          </Box>
                        )}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <StorefrontIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={t('admin:referredBy')}
                        secondary={
                          storeDetails.referredBy ? (
                            <Link
                              to={`/admin/stores/${storeDetails.referredBy.id_store}`}
                              style={{
                                textDecoration: 'none',
                                color: 'inherit',
                                '&:hover': {
                                  textDecoration: 'underline',
                                },
                              }}
                            >
                              {storeDetails.referredBy.store_name}
                            </Link>
                          ) : (
                            '-'
                          )
                        }
                      />
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={12} md={6}>
                  <List>
                    <ListItem>
                      <ListItemText primary="Created At" secondary={formatDate(storeDetails.created)} />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Last Updated" secondary={formatDate(storeDetails.updated)} />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary={t('admin:lastOrder')} secondary={formatDate(storeDetails.lastOrder?.purchase_date) || '-'} />
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          {/* Dashboard Stats Card */}
          <Grid item xs={12} md={4}>
            <Paper sx={{ p: { xs: 2, sm: 3 }, height: '100%', border: '1px solid #919EAB3D' }}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                {t('admin:quickStats')}
              </Typography>
              <DashCarousel isCompact />
            </Paper>
          </Grid>

          {/* Integrations Card */}
          <Grid item xs={12} md={4}>
            <Card sx={{ height: '100%', border: '1px solid #919EAB3D' }}>
              <CardHeader avatar={<IntegrationInstructionsIcon />} title="Integrations" />
              <CardContent>
                {storeDetails.integrations?.length > 0 ? (
                  <List>
                    {storeDetails.integrations.map((integration) => (
                      <ListItem key={integration.id}>
                        <ListItemIcon>
                          <Avatar src={getLogoUrl(integration.type)} alt={integration.type} variant="square" sx={{ width: 24, height: 24 }} />
                        </ListItemIcon>
                        <ListItemText primary={integration.platform} />
                        {integration.platform?.toLowerCase() === 'tiendanube' && (
                          <IconButton size="small" onClick={() => handleOpenIntegrationDetails(integration)}>
                            <InfoIcon fontSize="small" />
                          </IconButton>
                        )}
                        <Chip size="small" label={integration.status} color={integration.status === 'active' ? 'success' : 'default'} />
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  <Typography variant="body2" color="text.secondary" sx={{ p: 2, textAlign: 'center' }}>
                    {t('admin:noIntegrations')}
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Grid>

          {/* Users Card */}
          <Grid item xs={12} md={4}>
            <Card sx={{ height: '100%', border: '1px solid #919EAB3D' }}>
              <CardHeader
                avatar={<GroupIcon />}
                title="Users"
                action={(
                  <IconButton onClick={() => setOpenDialog(true)}>
                    <AddIcon />
                  </IconButton>
                )}
              />
              <CardContent>
                {storeDetails.merchants?.length > 0 ? (
                  <Box
                    sx={{
                      maxHeight: 300,
                      overflow: 'auto',
                      '&::-webkit-scrollbar': {
                        width: '8px',
                      },
                      '&::-webkit-scrollbar-track': {
                        backgroundColor: 'background.paper',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: 'grey.300',
                        borderRadius: '4px',
                      },
                    }}
                  >
                    <List disablePadding>
                      {storeDetails.merchants.map((user) => (
                        <ListItem key={user.Users_id_user}>
                          <ListItemIcon>
                            <Avatar>{user.email[0].toUpperCase()}</Avatar>
                          </ListItemIcon>
                          <ListItemText primary={user.email} />
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                ) : (
                  <Typography variant="body2" color="text.secondary" sx={{ p: 2, textAlign: 'center' }}>
                    {t('admin:noUsers')}
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Grid>

          {/* Referred Stores Card */}
          <Grid item xs={12} md={4}>
            <Card sx={{ height: '100%', border: '1px solid #919EAB3D' }}>
              <CardHeader avatar={<StorefrontIcon />} title={t('admin:referredStores')} />
              <CardContent>
                {storeDetails.referred?.length > 0 ? (
                  <Box
                    sx={{
                      maxHeight: 300,
                      overflow: 'auto',
                      '&::-webkit-scrollbar': {
                        width: '8px',
                      },
                      '&::-webkit-scrollbar-track': {
                        backgroundColor: 'background.paper',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: 'grey.300',
                        borderRadius: '4px',
                      },
                    }}
                  >
                    <List>
                      {storeDetails.referred.map((store) => (
                        <ListItem
                          key={store.id_store}
                          component={Link}
                          to={`/admin/stores/${store.id_store}`}
                          sx={{
                            textDecoration: 'none',
                            color: 'inherit',
                            '&:hover': {
                              backgroundColor: 'action.hover',
                            },
                          }}
                        >
                          <ListItemIcon>
                            <StorefrontIcon color="action" />
                          </ListItemIcon>
                          <ListItemText primary={store.store_name} />
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                ) : (
                  <Typography variant="body2" color="text.secondary" sx={{ p: 2, textAlign: 'center' }}>
                    {t('admin:noReferredStores')}
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Grid>

          {/* Payout Account Card */}
          <Grid item xs={12}>
            <Grid container spacing={3}>
              {/* Payout Account Card */}
              <Grid item xs={12} md={8}>
                <Card sx={{ border: '1px solid #919EAB3D' }}>
                  <CardHeader
                    title={t('admin:payoutAccount')}
                    action={
                      isPayoutEdited && (
                        <IconButton color="primary" onClick={handlePayoutSubmit} title={t('common:save')}>
                          <SaveIcon />
                        </IconButton>
                      )
                    }
                  />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          label={t('admin:accountName')}
                          value={payoutAccount.account_name || ''}
                          onChange={handlePayoutChange('account_name')}
                          placeholder={t('admin:accountName')}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          label={t('admin:accountNumber')}
                          value={payoutAccount.account_number || ''}
                          onChange={handlePayoutChange('account_number')}
                          placeholder={t('admin:accountNumber')}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>

              {/* Payout Frequency Card */}
              <Grid item xs={12} md={4}>
                <Card sx={{ border: '1px solid #919EAB3D' }}>
                  <CardHeader
                    title={t('admin:payoutFrequencyDays')}
                    action={
                      isFrequencyEdited && (
                        <IconButton
                          color="primary"
                          onClick={() => dispatch(updateStore(storeId, { payout_frequency_days: payoutFrequencyDays }))}
                          title={t('common:save')}
                        >
                          <SaveIcon />
                        </IconButton>
                      )
                    }
                  />
                  <CardContent>
                    <TextField
                      fullWidth
                      type="number"
                      value={payoutFrequencyDays}
                      onChange={(e) => {
                        setPayoutFrequencyDays(e.target.value);
                        setIsFrequencyEdited(true);
                      }}
                      placeholder="Enter days"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>

          {/* Plans Card */}
          <Grid item xs={12}>
            <Card sx={{ border: '1px solid #919EAB3D' }}>
              <CardHeader avatar={<SubscriptionsIcon />} title="Plans History" />
              <CardContent>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Plan Name</TableCell>
                        <TableCell>Details</TableCell>
                        <TableCell>Start Date</TableCell>
                        <TableCell>End Date</TableCell>
                        <TableCell>Status</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {storeDetails.plans?.map((plan) => (
                        <TableRow key={plan.Plans_id_plan}>
                          <TableCell>{plan.plan_name}</TableCell>
                          <TableCell>{plan.description}</TableCell>
                          <TableCell>{formatDate(plan.since)}</TableCell>
                          <TableCell>{formatDate(plan.until)}</TableCell>
                          <TableCell>
                            <Chip size="small" label={plan.status} color={plan.status === 'active' ? 'success' : 'default'} />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
        <MenuItem onClick={() => handleStoreAction(storeDetails.status === 'active' ? 'inactive' : 'active')}>
          {storeDetails.status === 'active' ? 'Deactivate Store' : 'Activate Store'}
        </MenuItem>
      </Menu>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog} // Changed from setOpenDialog(false)
      >
        <DialogTitle>Add New User</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 2 }}>
            <TextField fullWidth label="Search by email" value={searchEmail} onChange={(e) => setSearchEmail(e.target.value)} />
            <IconButton onClick={handleSearchUser}>
              <SearchIcon />
            </IconButton>
          </Box>
          {foundUsers?.length > 0 && (
            <List sx={{ mt: 2 }}>
              {foundUsers.map((user) => (
                <ListItem
                  key={user.id_user}
                  secondaryAction={(
                    <IconButton
                      edge="end"
                      onClick={() => handleAddMerchant(user.id_user)}
                      disabled={storeDetails.merchants?.some((m) => m.Users_id_user === user.id_user)}
                    >
                      <AddIcon />
                    </IconButton>
                  )}
                >
                  <ListItemText
                    primary={user.email}
                    secondary={storeDetails.merchants?.some((m) => m.Users_id_user === user.id_user) ? 'Already added' : ''}
                  />
                </ListItem>
              ))}
            </List>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openIntegrationDialog} onClose={() => setOpenIntegrationDialog(false)}>
        <DialogTitle>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            Tiendanube Integration Details
            <IconButton size="small" onClick={() => setOpenIntegrationDialog(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <List>
            <ListItem>
              <ListItemText
                primary="Access Tokens"
                secondary={(
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {Array.isArray(selectedIntegration?.details?.access_token) && selectedIntegration.details.access_token.length > 0 ? (
                      <CheckCircleIcon color="success" />
                    ) : (
                      'No access tokens'
                    )}
                  </Box>
                )}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Payment Providers"
                secondary={(
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {Array.isArray(selectedIntegration?.details?.payment_providers) && selectedIntegration.details.payment_providers.length > 0 ? (
                      <CheckCircleIcon color="success" />
                    ) : (
                      'No payment providers'
                    )}
                  </Box>
                )}
              />
            </ListItem>
          </List>
        </DialogContent>
      </Dialog>
    </TransitionComponent>
  );
}

export default StoreDetails;
