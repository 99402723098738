import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, useMediaQuery } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import NavPopover from './NavPopover/NavPopover';
import wavaLogo from '../../assets/dashboard/logo_wava.svg';
import dashLine from '../../assets/dashboard/navbar_line.svg';
import AccountPopover from './AccountPopover/AccountPopover';
import Navigation from './Navigation/Navigation';
import {
  LoggedContainer,
  LoggedLayout, LoggedNavbar, LogoStack, NavDataStack, WavaLogo,
} from './logged.styled';
import Footer from './Footer/Footer';
import WelcomeTourModal from '../Modals/WelcomeTourModal';

function Logged({ children, full = true }) {
  const { t } = useTranslation(['dashboard']);
  const navigate = useNavigate();
  const location = useLocation();
  // const user = useSelector((state) => state.user);
  const stores = useSelector((state) => state.merchant.stores);
  const selectedStore = useSelector((state) => state.merchant.selectedStore);
  const configStep = selectedStore?.config?.tour_step || 10;
  const configTourComplete = selectedStore?.config?.tour_complete || true;
  const welcomeTourStep = useSelector((state) => state.merchant.welcomeTour.tour_step);
  const isMobile = useMediaQuery('(max-width:900px)');
  const homePath = location?.pathname === '/dashboard' || location?.pathname === '/';
  const [navPath, setNavPath] = useState('Dashboard');
  // const isMerchant = stores.length > 0; // && user.id_merchant !== null;
  // const role = useSelector((state) => state.role);

  // useEffect(() => {
  //   if (isMerchant && role === 'Shopper') {
  //     dispatch({ type: 'SWITCH_ROLE' });
  //   }
  // }, []);

  useEffect(() => {
    if (stores.length === 0) {
      navigate('/onboard');
    }
    switch (location?.pathname) {
      case '/':
        setNavPath('Dashboard');
        break;
      case '/dashboard':
        setNavPath('Dashboard');
        break;
      case '/profile':
        setNavPath(t('navigation-profile'));
        break;
      case '/orders':
        setNavPath(t('navigation-orders'));
        break;
      case '/paymentLink':
        setNavPath(t('navigation-payment-link'));
        break;
      case '/storeLinks':
        setNavPath(t('navigation-store-links'));
        break;
      case '/config':
        setNavPath(t('navigation-config'));
        break;
      case '/onboard':
        setNavPath('Onboarding');
        break;
      case '/admin/stores':
        setNavPath(t('navigation-admin-stores'));
        break;
      default:
        setNavPath('Dashboard');
        break;
    }
  }, [location?.pathname, t]);

  return (
    <LoggedLayout>
      <WelcomeTourModal open={!isMobile && !configTourComplete && configStep === 0 && welcomeTourStep === 0 && homePath} />
      <LoggedNavbar>
        <LogoStack>
          <NavPopover />
          <WavaLogo src={wavaLogo} alt="Wava" />
          <NavDataStack>
            <img src={dashLine} alt="" />
            <Typography variant="body2" sx={{ fontWeight: 600 }}>
              {navPath}
            </Typography>
          </NavDataStack>
        </LogoStack>
        <AccountPopover />
      </LoggedNavbar>
      <LoggedContainer>
        {location?.pathname !== '/onboard' && full && <Navigation />}
        {children}
      </LoggedContainer>
      <Footer />
    </LoggedLayout>
  );
}

export default Logged;
