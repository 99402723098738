/* eslint-disable camelcase */
/* eslint-disable no-constant-condition */
/* eslint-disable arrow-body-style */
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import TransitionComponent from '../TransitionComponent';
import { fDateShort } from '../../../utils/formatTime';
import LinksTableHead from './LinksTableHead';
import LinksToolbar from './LinksToolbar';
import { getStoreLinks } from '../../../redux/actions/storesActions';
import { fCurrency } from '../../../utils/formatNumber';

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
};

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

function StoreLinks() {
  const dispatch = useDispatch();
  const { t } = useTranslation(['payment-link']);
  const user = useSelector((state) => state.user);
  // const role = useSelector((state) => state.role);
  const stores = useSelector((state) => state.merchant.stores);
  const selectedStore = useSelector((state) => state.merchant.selectedStore);
  const storeLinksList = useSelector((state) => state.paymentLink.storeLinks);
  const [sortOrder, setSortOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('date');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = useState([]);
  const isMerchant = stores.length > 0; // && user.id_merchant !== null;

  const createData = (url, order_key, created, email, amount, description) => {
    return {
      url,
      order_key,
      created,
      email,
      amount,
      description,
    };
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && sortOrder === 'asc';
    setSortOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () => stableSort(rows, getComparator(sortOrder, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [sortOrder, orderBy, page, rowsPerPage, rows],
  );

  const handleCopyLink = (link) => {
    navigator.clipboard.writeText(link);
    dispatch({
      type: 'SET_MESSAGE',
      payload: { text: t('links-list-copy-success') || 'Copied to clipboard!', kind: 'success' },
    });
  };

  useEffect(() => {
    dispatch(getStoreLinks(selectedStore?.id_store));
  }, [selectedStore]);

  useEffect(() => {
    if (storeLinksList?.length > 0) {
      setRows(storeLinksList?.map((o) => createData(o.url, o.order_key, o.created, o.email, o.amount, o.description)));
    }
  }, [storeLinksList?.length, selectedStore]);

  return (
    <TransitionComponent>
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <LinksToolbar stores={stores} isMerchant={isMerchant} isAdmin={user.is_admin} />
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
              <LinksTableHead order={sortOrder} orderBy={orderBy} onRequestSort={handleRequestSort} />
              <TableBody>
                {rows.length > 0 ? (
                  visibleRows.map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        key={row.created}
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        sx={{ cursor: 'pointer' }}
                        onClick={() => handleCopyLink(row.url)}
                      >
                        <TableCell padding="checkbox">{}</TableCell>
                        <TableCell component="th" id={labelId} scope="row" padding="none">
                          <Tooltip title={row.description || ''}>
                            <span>{row.description ? `${row.description.substring(0, 20)}${row.description.length > 20 ? '...' : ''}` : '-'}</span>
                          </Tooltip>
                        </TableCell>
                        <TableCell align="right">{row.amount ? fCurrency(row.amount) : '-'}</TableCell>
                        <TableCell align="left">{row.email || '-'}</TableCell>
                        <TableCell align="left">{row.order_key ? row.order_key : '-'}</TableCell>
                        <TableCell align="left">{row.url}</TableCell>
                        <TableCell align="right">{row.created ? fDateShort(row.created) : '-'}</TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow hover role="checkbox" tabIndex={-1}>
                    <TableCell padding="checkbox">{}</TableCell>
                    <TableCell component="th" scope="row" padding="none">
                      -
                    </TableCell>
                    <TableCell align="right">-</TableCell>
                    <TableCell align="left">-</TableCell>
                    <TableCell align="left">-</TableCell>
                    <TableCell align="left">-</TableCell>
                    <TableCell align="right">-</TableCell>
                  </TableRow>
                )}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </TransitionComponent>
  );
}

export default StoreLinks;
