/* eslint-disable camelcase */
/* eslint-disable no-unused-expressions */
import axios from 'axios';

const override_timeout = 1000000;

export const api = (props) => {
  const {
    url,
    data = {},
    successHandler = (response) => response,
    errorHandler = (error) => {
      console.error('fetch failed', error);
    },
  } = props;
  return axios
    .post(process.env.REACT_APP_API_URL + url, JSON.stringify(data), {
      timeout: override_timeout,
    })
    .then((response) => response.data)
    .then((decodedResponse) => {
      decodedResponse.error ? errorHandler(decodedResponse?.result?.message || decodedResponse?.result || '') : successHandler(decodedResponse);
    })
    .catch((err) => {
      console.log('err', err);
      errorHandler(err);
    });
};

export const apiGet = (props) => {
  const {
    url,
    successHandler = (response) => response,
    errorHandler = (error) => {
      console.error('fetch failed', error);
    },
  } = props;
  return axios
    .get(process.env.REACT_APP_API_URL + url)
    .then((response) => response.data)
    .then((decodedResponse) => {
      decodedResponse.error ? errorHandler(decodedResponse?.result?.message || decodedResponse?.result || '') : successHandler(decodedResponse);
    })
    .catch((err) => {
      errorHandler(err.data);
    });
};

export const apiPatch = (props) => {
  const {
    url,
    data = {},
    successHandler = (response) => response,
    errorHandler = (error) => {
      console.error('fetch failed', error);
    },
  } = props;
  return axios
    .patch(process.env.REACT_APP_API_URL + url, JSON.stringify(data))
    .then((response) => response.data)
    .then((decodedResponse) => {
      decodedResponse.error ? errorHandler(decodedResponse?.result?.message || decodedResponse?.result || '') : successHandler(decodedResponse);
    })
    .catch((err) => {
      console.log('err', err);
      errorHandler(err);
    });
};

export const apiPut = (props) => {
  const {
    url,
    data = {},
    successHandler = (response) => response,
    errorHandler = (error) => {
      console.error('fetch failed', error);
    },
  } = props;
  return axios
    .put(process.env.REACT_APP_API_URL + url, JSON.stringify(data))
    .then((response) => response.data)
    .then((decodedResponse) => {
      decodedResponse.error ? errorHandler(decodedResponse?.result?.message || decodedResponse?.result || '') : successHandler(decodedResponse);
    })
    .catch((err) => {
      console.log('err', err);
      errorHandler(err);
    });
};

export const apiDelete = (props) => {
  const {
    url,
    successHandler = (response) => response,
    errorHandler = (error) => {
      console.error('fetch failed', error);
    },
  } = props;
  return axios
    .delete(process.env.REACT_APP_API_URL + url)
    .then((response) => response.data)
    .then((decodedResponse) => {
      decodedResponse.error ? errorHandler(decodedResponse?.result?.message || decodedResponse?.result || '') : successHandler(decodedResponse);
    })
    .catch((err) => {
      console.log('err', err);
      errorHandler(err);
    });
};
