const initialState = {
  stores: [],
  loadingStores: false,
  loading: false,
  error: null,
  storeDetails: null,
};

export default function adminReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_ADMIN_STORES':
      return {
        ...state,
        stores: action.payload,
      };
    case 'SET_LOADING_ADMIN_STORES':
      return {
        ...state,
        loadingStores: action.payload,
      };
    case 'FETCH_STORE_DETAILS_LOADING':
      return {
        ...state,
        loading: action.payload,
      };
    case 'FETCH_STORE_DETAILS_SUCCESS':
      return {
        ...state,
        loading: false,
        storeDetails: action.payload,
      };
    case 'FETCH_STORE_DETAILS_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case 'SET_USER_SEARCH_RESULTS':
      return {
        ...state,
        userSearchResults: action.payload,
      };
    default:
      return state;
  }
}
