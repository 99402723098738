import React from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import Logged from './components/Logged/Logged';
import getCookie from './utils/getCookie';

// eslint-disable-next-line no-unused-vars
function PrivateRoute({ element: Element, title, ...rest }) {
  const auth = getCookie('wavaAuth');
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch({ type: 'SET_USER_AUTH', payload: !!auth });
  }, [auth, dispatch]);
  return auth ? (
    <Logged>
      <Outlet />
    </Logged>
  ) : (
    <Navigate replace to="/login" />
  );
}

export default PrivateRoute;
