import React from 'react';
import { useMediaQuery } from '@mui/material';
import { DashChartsContainer } from '../dashboard.styled';
// import DashPieChart from './DashPieChart';
import DashLineChart from './DashLineChart';
// import DashLastMonth from './DashLastMonth';
import PaymentLinkCard from '../../Logged/PaymentLink/PaymentLinkCard';

function DashboardCharts() {
  const isMobile = useMediaQuery('(max-width:400px)');
  return (
    <DashChartsContainer>
      <DashLineChart />
      {/* <DashPieChart /> */}
      {isMobile && <PaymentLinkCard />}
      {/* <DashLastMonth /> */}
    </DashChartsContainer>
  );
}

export default DashboardCharts;
