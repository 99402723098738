import React, { Suspense, useEffect } from 'react';
import {
  Route, Routes, useLocation, useNavigate,
} from 'react-router-dom';
// import TagManager from 'react-gtm-module';
import mixpanel from 'mixpanel-browser';
import { useDispatch, useSelector } from 'react-redux';
import NotistackProvider from './components/NotistackProvider';
import AuthProvider from './components/AuthProvider';
import NotFound from './components/NotFound';
import PrivateRoute from './PrivateRoute';
import Logout from './components/Logout';
import ThemeProvider from './theme';
import Dashboard from './components/Dashboard/Dashboard';
import Code from './components/Login/Code';
import OrdersList from './components/Logged/OrdersList/OrdersList';
import PaymentLink from './components/Logged/PaymentLink/PaymentLink';
import StoreLinks from './components/Logged/StoreLinks/StoreLinks';
import StoresList from './components/Admin/StoresList/StoresList';
import Config from './components/Logged/Config/Config';
import Login from './components/Login/Login';
import LoginFrame from './components/Login/LoginFrame';
import SelectStore from './components/Login/SelectStore';
import OnboardSuccess from './components/Login/OnboardSuccess';
import SignUp from './components/Login/SignUp';
import AppSkeleton from './components/Skeletons/AppSkeleton';
import NewStore from './components/Logged/NewStore/NewStore';
import FilloutForm from './components/Logged/FilloutForm/FilloutForm';
import StoreDetails from './components/Admin/StoreDetails/StoreDetails';

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuthenticated } = useSelector((state) => state.user);
  const location = useLocation();
  // const tagManagerArgs = {
  //   gtmId: 'GTM-P9G683P',
  // };

  useEffect(() => {
    const queryParams = Object.fromEntries(new URLSearchParams(location?.search));
    if (Object.keys(queryParams).length > 0) {
      if (queryParams?.platform) {
        dispatch({
          type: 'SET_FROM_PLATFORM',
          payload: queryParams.platform,
        });
        dispatch({
          type: 'SET_INTEGRATIONS_BODY',
          payload: { platform: queryParams.platform },
        });
      }
      if (queryParams?.code) {
        dispatch({
          type: 'SET_ONBOARD_CODE',
          payload: queryParams.code,
        });
        dispatch({
          type: 'SET_INTEGRATIONS_BODY',
          payload: { code: queryParams.code },
        });
      }
      if (queryParams?.refcode) {
        dispatch({
          type: 'SET_ONBOARD_REFCODE',
          payload: queryParams.refcode,
        });
      }
      if (queryParams?.is) {
        dispatch({
          type: 'SET_STORE_ID',
          payload: Number(queryParams.is),
        });
      }
      if (queryParams?.io) {
        dispatch({
          type: 'SET_ORDER_ID',
          payload: Number(queryParams.io),
        });
      }
      // WC
      if (queryParams?.shop) {
        dispatch({
          type: 'SET_INTEGRATIONS_BODY',
          payload: { shop: queryParams.shop },
        });
      }
      if (queryParams?.orders) {
        dispatch({
          type: 'SET_INTEGRATIONS_BODY',
          payload: { orders: queryParams.orders },
        });
      }
      if (queryParams?.install) {
        dispatch({
          type: 'SET_INTEGRATIONS_BODY',
          payload: { install: queryParams.install },
        });
      }
      if (queryParams?.redirect) {
        dispatch({
          type: 'SET_INTEGRATIONS_BODY',
          payload: { redirect: queryParams.redirect },
        });
      }
      if (queryParams?.version) {
        dispatch({
          type: 'SET_INTEGRATIONS_BODY',
          payload: { version: queryParams.version },
        });
      }
    }
    if (location && location?.pathname) {
      dispatch({
        type: 'SET_LOCATION',
        payload: location?.pathname,
      });
      if (!isAuthenticated && location?.pathname === '/onboard') {
        navigate('/signup');
      }
    }
    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);

    // setTimeout(() => {
    //   TagManager.initialize(tagManagerArgs);
    // }, 100);
  }, []);

  return (
    <ThemeProvider>
      <NotistackProvider>
        <AuthProvider>
          <Suspense key={location?.pathname} fallback={<AppSkeleton path={location?.pathname} />}>
            <Routes>
              <Route path="*" element={<NotFound />} />
              <Route element={<PrivateRoute />}>
                <Route exact path="/" element={<Dashboard />} title="Home" />
                <Route path="/dashboard" element={<Dashboard />} title="Dashboard" />
                <Route path="/orders" element={<OrdersList />} title="Orders" />
                <Route path="/paymentLink" element={<PaymentLink />} title="Payment links" />
                <Route path="/storeLinks" element={<StoreLinks />} title="Payment links" />
                <Route path="/config" element={<Config />} title="Configuration" />
                <Route path="/admin/stores" element={<StoresList />} title="Stores" />
                <Route path="/admin/stores/:storeId" element={<StoreDetails />} title="Store Details" />
              </Route>
              <Route element={<LoginFrame />}>
                <Route path="/selectstore" element={<SelectStore />} title="Select store" />
                <Route path="/onboard" element={<NewStore />} title="Onboarding" />
                <Route path="/fillout" element={<FilloutForm />} title="Onboarding" />
                <Route path="/success" element={<OnboardSuccess />} title="Success" />
                <Route path="/login" element={<Login />} title="Login" />
                <Route path="/signup" element={<SignUp />} title="Sign up" />
                <Route path="/code" element={<Code />} title="Authentication" />
              </Route>
              <Route path="/logout" element={<Logout />} />
            </Routes>
          </Suspense>
        </AuthProvider>
      </NotistackProvider>
    </ThemeProvider>
  );
}

export default App;
