/* eslint-disable react/jsx-indent */
import React, { useEffect } from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CircularProgress, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  ImageBox, LoginWrapper, SelectWrapper, StoreSelection,
} from './Login.styled';
import newStoreLogo from '../../assets/login/new_store_img.svg';
import { getStores, setNewIntegration } from '../../redux/actions/storesActions';

function SelectStore() {
  const { t } = useTranslation(['login']);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const stores = useSelector((state) => state.merchant.stores);
  // const selectedStore = useSelector((state) => state.merchant.selectedStore);
  const platform = useSelector((state) => state.platform);
  const integrationsBody = useSelector((state) => state.merchant.integrationsBody);

  const locationIs = useSelector((state) => state.location.storeId);
  const locationIo = useSelector((state) => state.location.orderId);
  const storeFromParams = stores?.find((s) => s.id_store === locationIs) || null;

  const locationPath = useSelector((state) => state.location.path);
  const restrictedPath = locationPath !== '' && locationPath !== '/login'
    && locationPath !== '/signup' && locationPath !== '/code'
    && locationPath !== '/onboard' && locationPath !== '/selectstore';

  const handleStoreSelect = (store) => {
    dispatch({
      type: 'SET_SELECTED_STORE',
      payload: store,
    });
    if (store.status === 'draft') {
      navigate('/fillout');
    } else if (platform) {
      dispatch(setNewIntegration(
        store?.id_store,
        integrationsBody,
        navigate,
      ));
    } else if (restrictedPath) {
      navigate(locationPath);
    } else {
      navigate('/dashboard');
    }
  };

  useEffect(() => {
    // * *
    // * Get updated stores data before any action or navigation
    // *
    dispatch(getStores({}));
    if (!user.isAuthenticated) {
      navigate('/login');
    }
  }, []);

  useEffect(() => {
    if (locationIs && locationIo && storeFromParams) {
      dispatch({
        type: 'SET_SELECTED_STORE',
        payload: storeFromParams,
      });
      navigate('/orders');
    }
  }, [locationIs, locationIo]);

  return (
    <LoginWrapper>
      <Typography variant="h3">
        {t('select-title')}
      </Typography>
      <Typography variant="body1" dangerouslySetInnerHTML={{ __html: t('select-subtitle', { user_email: user.email }) }} />
      <SelectWrapper>
        {stores?.length > 0 ? (
          stores.map((store) => (
            <StoreSelection
              key={store?.id_store}
              onClick={() => handleStoreSelect(store)}
            >
              <ImageBox>
                <img
                  src={store?.logo_image || 'https://wava-assets.s3.amazonaws.com/wava-dashboard/section-settings/icon-storefront.svg'}
                  alt="new"
                />
              </ImageBox>
              <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" width="100%">
                <Typography variant="body2" fontWeight="bold">
                  {store?.store_name}
                </Typography>
                <Typography variant="caption">
                  {store?.store_url}
                </Typography>
              </Stack>
              <ChevronRightIcon />
            </StoreSelection>
          ))
        ) : <CircularProgress sx={{ display: stores?.length === 0 ? 'none' : 'flex', alignSelf: 'center' }} />}
        <StoreSelection onClick={() => navigate('/onboard')}>
          <ImageBox>
            <img src={newStoreLogo} alt="new" />
          </ImageBox>
          <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" width="100%">
            <Typography variant="body2" fontWeight="bold">
              {t('select-cta1')}
            </Typography>
            <Typography variant="caption">
              {t('select-cta2')}
            </Typography>
          </Stack>
          <ChevronRightIcon />
        </StoreSelection>
      </SelectWrapper>
    </LoginWrapper>
  );
}

export default SelectStore;
