import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { PanelContainer, PanelSection } from '../config.styled';
import IntegrationsCard from './IntegrationModals';
import { getStores, getTiendaNubeImages } from '../../../../redux/actions/storesActions';

function Integrations() {
  const dispatch = useDispatch();
  const { t } = useTranslation(['configuration']);
  const selectedStore = useSelector((state) => state.merchant.selectedStore);
  const tnImages = useSelector((state) => state.merchant.tnImages);
  const loadingTnImages = useSelector((state) => state.merchant.loadingTnImages);
  const loadingWh = useSelector((state) => state.merchant.loadingWebhook);
  const loadingIntStatus = useSelector((state) => state.merchant.integrationStatusLoading);

  const wooIntegration = selectedStore?.integrations?.find((i) => i.platform?.toLowerCase() === 'woocommerce');
  const tnIntegration = selectedStore?.integrations?.find((i) => i.platform?.toLowerCase() === 'tiendanube');
  const whList = selectedStore?.integrations?.filter((i) => i.platform?.toLowerCase() === 'webhook');

  useEffect(() => {
    if (selectedStore) {
      dispatch(
        getStores({
          updateSelectedStore: true,
          selectedStoreId: selectedStore.id_store,
        }),
      );
      if (tnImages.length === 0) {
        dispatch(getTiendaNubeImages());
      }
    }
  }, [selectedStore?.id_store]);

  return (
    <PanelContainer>
      <PanelSection
        active={1}
        sx={{
          paddingTop: 1,
          flexWrap: 'wrap',
          flexDirection: { md: 'column', lg: 'row' },
          alignItems: { xs: 'center', md: 'stretch' },
          gap: '2rem',
        }}
      >
        {selectedStore?.country_name !== 'Mexico' && (
          <IntegrationsCard
            hasIntegration={wooIntegration ? 1 : 0}
            status={wooIntegration?.status === 'active' ? 1 : 0}
            logo="https://wava-assets.s3.amazonaws.com/wava-dashboard/section-settings/woocommerce-logo.svg"
            title={t('integrations-woo')}
            subtitle={t('integrations-woo-desc')}
            link="https://wordpress.org/plugins/wava-payment/"
            modalKey="woo"
            modalProps={{
              storeId: selectedStore?.id_store,
              intData: wooIntegration,
              loading: loadingIntStatus,
            }}
          />
        )}
        <IntegrationsCard
          hasIntegration={tnIntegration ? 1 : 0}
          status={tnIntegration?.status === 'active' ? 1 : 0}
          logo="https://wava-assets.s3.amazonaws.com/wava-dashboard/section-settings/tiendanube-logo.svg"
          title={t('integrations-tn')}
          subtitle={t('integrations-tn-desc')}
          link="https://www.tiendanube.com/co/tienda-aplicaciones-nube/wava"
          modalKey="tn"
          modalProps={{
            storeId: selectedStore?.id_store,
            intData: tnIntegration,
            loading: loadingIntStatus,
            loadingImages: loadingTnImages,
            images: tnImages,
          }}
        />
        <IntegrationsCard
          hasIntegration={1}
          status={0}
          logo="https://wava-assets.s3.amazonaws.com/wava-dashboard/section-settings/terminal-logo.svg"
          title={t('integrations-custom')}
          subtitle={t('integrations-custom-desc')}
          modalKey="api"
          modalProps={{
            mk: selectedStore?.merchant_key,
            storeId: selectedStore?.id_store,
            loadingWh,
            webhooks: whList,
          }}
        />
      </PanelSection>
    </PanelContainer>
  );
}

export default Integrations;
