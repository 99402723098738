/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-indent */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Stack, Typography, Grid, Box, IconButton, Card, CardHeader, CardContent } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import StorefrontIcon from '@mui/icons-material/Storefront';
import EmailIcon from '@mui/icons-material/Email';
import ShareIcon from '@mui/icons-material/Share';
import PaymentIcon from '@mui/icons-material/Payment';
import { ConfigCta, ConfigLoadingCta, LogoContainer, LogoImage, PanelContainer } from '../config.styled';
import { TextInputStyled } from '../../../StepsCheckout/stepscheckout.styled';
import { editStoreConfig, setStoreLogo, getStore } from '../../../../redux/actions/storesActions';

function General() {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation(['configuration']);
  const user = useSelector((state) => state.user);
  const selectedStore = useSelector((state) => state.merchant.selectedStore);
  const loadingStores = useSelector((state) => state.merchant.loadingStores);
  const loadingStoreLogo = useSelector((state) => state.merchant.loadingStoreLogo);
  const storeLogo = selectedStore?.logo_image || 'https://wava-assets.s3.amazonaws.com/wava-dashboard/section-settings/icon-storefront.svg';
  const [storeName, setStoreName] = useState(selectedStore?.store_name);
  const [file, setFile] = useState(null);
  const [imgPreview, setImgPreview] = useState(false);

  const handleStoreNameChange = () => {
    if (storeName.length > 1 && storeName !== selectedStore?.store_name) {
      dispatch(
        editStoreConfig(
          selectedStore?.id_store,
          { store_name: storeName?.trim() },
          {
            success: t('config-edit-success'),
            error: t('config-edit-error'),
          },
        ),
      );
    }
  };

  const handleCancelChanges = (setlogoSuccess) => {
    if (setlogoSuccess) {
      document.getElementById('storeLogo').value = '';
      setFile(null);
    } else {
      document.getElementById('storeLogo').value = '';
      setImgPreview(null);
      setFile(null);
    }
  };

  const convertToBase64 = (fileData) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(fileData);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const uploadFile = async () => {
    try {
      dispatch({
        type: 'SET_LOADING_STORE_LOGO',
        payload: true,
      });

      const base64 = await convertToBase64(file);
      const fileExtension = file.name.split('.').pop().toLowerCase();
      const base64Data = base64.split(',')[1];

      dispatch(
        setStoreLogo(
          selectedStore?.id_store,
          {
            logo: {
              data: base64Data,
              extension: fileExtension,
              mime_type: file.type,
            },
          },
          {
            success: t('config-tab-general-store-logo-success'),
            error: t('config-tab-general-store-logo-err'),
          },
          handleCancelChanges,
        ),
      );
    } catch (error) {
      dispatch({
        type: 'SET_LOADING_STORE_LOGO',
        payload: false,
      });
      dispatch({
        type: 'SET_MESSAGE',
        payload: {
          text: t('config-tab-general-store-logo-err') || 'Error updating store logo',
          kind: 'error',
        },
      });
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setImgPreview(URL.createObjectURL(e.target.files[0]));
    }
    setFile(e.target.files[0]);
  };

  const getNextPayoutDate = () => {
    if (!selectedStore?.payout_frequency_days) {
      // Original logic for Tuesday/Friday payments
      const today = new Date();
      const nextDate = new Date(today);
      const currentDay = today.getDay(); // 0 = Sunday, 2 = Tuesday, 5 = Friday

      if (currentDay < 2) {
        // Before Tuesday
        nextDate.setDate(today.getDate() + (2 - currentDay));
      } else if (currentDay < 5) {
        // Between Tuesday and Friday
        nextDate.setDate(today.getDate() + (5 - currentDay));
      } else {
        // After Friday
        nextDate.setDate(today.getDate() + (9 - currentDay)); // Next Tuesday
      }

      return nextDate.toLocaleDateString(i18n.language === 'es' ? 'es-ES' : 'en-US', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    }

    // Logic for 2nd and 4th Friday payments
    const today = new Date();
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();

    // Find all Fridays in current month
    const fridays = [];
    const firstDay = new Date(currentYear, currentMonth, 1);
    const lastDay = new Date(currentYear, currentMonth + 1, 0);

    for (let d = firstDay; d <= lastDay; d.setDate(d.getDate() + 1)) {
      if (d.getDay() === 5) {
        // Friday
        fridays.push(new Date(d));
      }
    }

    // Get 2nd and 4th Fridays
    const secondFriday = fridays[1];
    const fourthFriday = fridays[3];

    // Determine next payout date
    let nextPayoutDate;
    if (today <= secondFriday) {
      nextPayoutDate = secondFriday;
    } else if (today <= fourthFriday) {
      nextPayoutDate = fourthFriday;
    } else {
      // If we're past 4th Friday, get 2nd Friday of next month
      const nextMonth = new Date(currentYear, currentMonth + 1, 1);
      const nextMonthFridays = [];
      const nextMonthLastDay = new Date(currentYear, currentMonth + 2, 0);

      for (let d = nextMonth; d <= nextMonthLastDay; d.setDate(d.getDate() + 1)) {
        if (d.getDay() === 5) {
          nextMonthFridays.push(new Date(d));
        }
      }
      const [, nextMonthSecondFriday] = nextMonthFridays;
      nextPayoutDate = nextMonthSecondFriday;
    }

    return nextPayoutDate.toLocaleDateString(i18n.language === 'es' ? 'es-ES' : 'en-US', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  useEffect(() => {
    setStoreName(selectedStore?.store_name);
  }, [selectedStore]);

  useEffect(() => {
    if (selectedStore?.id_store) {
      dispatch(getStore({ storeId: selectedStore.id_store, fromOnboard: true }));
    }
  }, []); // Empty dependency array means it runs once on mount

  return (
    <PanelContainer
      sx={{
        maxWidth: '80%', // slightly wider than 70% to ensure good layout on different screens
        width: '100%', // ensures it's responsive
      }}
    >
      {/* First Row: Store Name, Email, and Referral Link */}
      <Grid container spacing={3} sx={{ mb: 3 }}>
        <Grid item xs={12} md={4}>
          <Card sx={{ height: '100%', border: '1px solid #919EAB3D' }}>
            <CardHeader avatar={<StorefrontIcon />} title={t('config-tab-general-storename')} />
            <CardContent>
              <TextInputStyled
                fullWidth
                value={storeName}
                onChange={(e) => setStoreName(e.target.value)}
                label={t('config-tab-general-storename-label')}
              />
              <Stack direction="row" gap={2} sx={{ mt: 2 }}>
                <ConfigCta
                  disabled={storeName === selectedStore?.store_name}
                  variant="outlined"
                  onClick={() => setStoreName(selectedStore?.store_name)}
                >
                  {t('config-cancel')}
                </ConfigCta>
                <ConfigLoadingCta
                  disabled={storeName?.length < 2 || storeName === selectedStore?.store_name}
                  variant="contained"
                  onClick={() => handleStoreNameChange()}
                  loading={loadingStores}
                >
                  {t('config-save')}
                </ConfigLoadingCta>
              </Stack>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card sx={{ height: '100%', border: '1px solid #919EAB3D' }}>
            <CardHeader avatar={<EmailIcon />} title={t('config-tab-general-email-title')} />
            <CardContent>
              <TextInputStyled fullWidth value={user.email} label={t('config-tab-general-email-label')} disabled />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card sx={{ height: '100%', border: '1px solid #919EAB3D' }}>
            <CardHeader avatar={<ShareIcon />} title={t('config-tab-general-referral')} />
            <CardContent>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  backgroundColor: 'action.hover',
                  borderRadius: 1,
                  p: 2,
                }}
              >
                <Typography variant="body1" sx={{ flex: 1 }}>
                  {t('config-tab-general-referral-info')}
                </Typography>
                <IconButton
                  sx={{
                    backgroundColor: 'primary.main',
                    color: 'primary.contrastText',
                    '&:hover': {
                      backgroundColor: 'primary.dark',
                    },
                    width: 40,
                    height: 40,
                  }}
                  onClick={() => {
                    navigator.clipboard.writeText(selectedStore?.referral_link || '');
                    dispatch({
                      type: 'SET_MESSAGE',
                      payload: {
                        text: t('config-tab-general-referral-link-copied'),
                        kind: 'success',
                      },
                    });
                  }}
                >
                  <ContentCopyIcon />
                </IconButton>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Second Row: Payment Account and Store Logo */}
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Card sx={{ border: '1px solid #919EAB3D' }}>
            <CardHeader avatar={<PaymentIcon />} title={t('config-tab-general-payout-info')} />
            <CardContent>
              <Typography variant="body1" sx={{ mb: 2 }}>
                {t('config-tab-general-next-payout', { date: getNextPayoutDate() })}
              </Typography>
              {selectedStore?.payoutAccount?.account_number ? (
                <Stack spacing={2} sx={{ maxWidth: '500px' }}>
                  <TextInputStyled value={selectedStore?.payoutAccount?.account_name || ''} label={t('config-tab-general-account-name')} disabled />
                  <TextInputStyled
                    value={selectedStore?.payoutAccount?.account_number || ''}
                    label={t('config-tab-general-account-number')}
                    disabled
                  />
                </Stack>
              ) : (
                <Typography variant="body1" color="text.secondary">
                  {t('config-tab-general-payout-pending')}
                </Typography>
              )}
              <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
                <a href="https://wava.co/tarifas/" target="_blank" rel="noopener noreferrer">
                  {t('config-tab-general-fees-info')}
                </a>
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card sx={{ border: '1px solid #919EAB3D' }}>
            <CardHeader avatar={<StorefrontIcon />} title={t('config-tab-general-store-logo')} />
            <CardContent>
              <Typography variant="body2" sx={{ mb: 2 }}>
                {t('config-tab-general-store-logo-info')}
              </Typography>
              <Stack direction="row" gap={2} alignItems="center">
                <LogoContainer>
                  <LogoImage src={imgPreview || storeLogo} alt="Store logo" />
                </LogoContainer>
                <input type="file" id="storeLogo" accept="image/*,.png" onChange={(e) => handleFileChange(e)} />
              </Stack>
              <Stack direction="row" gap={2} sx={{ mt: 2 }}>
                <ConfigCta variant="outlined" disabled={!file || loadingStoreLogo} onClick={() => handleCancelChanges()}>
                  {t('config-cancel')}
                </ConfigCta>
                <ConfigLoadingCta variant="contained" disabled={!file} loading={loadingStoreLogo} onClick={() => uploadFile()}>
                  {t('config-save')}
                </ConfigLoadingCta>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </PanelContainer>
  );
}

export default General;
