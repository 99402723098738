import React from 'react';
import PropTypes from 'prop-types';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import Box from '@mui/material/Box';
import { visuallyHidden } from '@mui/utils';

const headCells = [
  {
    id: 'id',
    numeric: false,
    label: 'ID',
  },
  {
    id: 'name',
    numeric: false,
    label: 'Store Name',
    tooltip: true,
  },
  {
    id: 'nit',
    numeric: false,
    label: 'NIT',
  },
  {
    id: 'url',
    numeric: false,
    label: 'URL',
    tooltip: true,
  },
  {
    id: 'country',
    numeric: false,
    label: 'Country',
  },
  {
    id: 'status',
    numeric: false,
    label: 'Status',
  },
  {
    id: 'actions',
    numeric: false,
    label: 'Actions',
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox" /> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id && headCell.id !== 'select' && headCell.id !== 'actions'}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={headCell.id !== 'select' && headCell.id !== 'actions' ? createSortHandler(headCell.id) : undefined}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default EnhancedTableHead;
