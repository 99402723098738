import { api, apiGet, apiPatch } from '../../utils/api';

export const getStores = ({
  navigate = false, fromOnboard, updateSelectedStore, selectedStoreId,
}) => (dispatch) => apiGet({
  url: 'stores',
  successHandler: (data) => {
    dispatch({
      type: 'SET_STORES',
      payload: data.result,
    });
    if (updateSelectedStore && selectedStoreId) {
      dispatch({
        type: 'SET_SELECTED_STORE',
        payload: data.result?.find((s) => s.id_store === selectedStoreId),
      });
    }
    dispatch({
      type: 'SET_LOADING_STORES',
      payload: false,
    });
    if (navigate) {
      if (data?.result?.length === 0) {
        navigate('/onboard');
      } else if (data?.result?.find((s) => s.status === 'draft') && fromOnboard) {
        navigate('/fillout');
      } else {
        navigate('/selectstore');
      }
    }
  },
  errorHandler: (error) => {
    dispatch({
      type: 'SET_LOADING_STORES',
      payload: false,
    });
    dispatch({
      type: 'SET_MESSAGE',
      payload: { text: error, kind: 'error' },
    });
  },
});

export const getStore = ({
  storeId, fromOnboard,
}) => (dispatch) => apiGet({
  url: `stores/${storeId}`,
  successHandler: (data) => {
    dispatch({
      type: 'SET_STORE',
      payload: data.result,
      storeId,
    });
    dispatch({
      type: 'SET_LOADING_STORES',
      payload: false,
    });
    if (fromOnboard) {
      dispatch({
        type: 'SET_SELECTED_STORE',
        payload: data.result,
      });
    }
  },
  errorHandler: (error) => {
    dispatch({
      type: 'SET_LOADING_STORES',
      payload: false,
    });
    dispatch({
      type: 'SET_MESSAGE',
      payload: { text: error, kind: 'error' },
    });
  },
});

export const createNewStore = (data, navigate) => (dispatch) => {
  dispatch({
    type: 'SET_LOADING_STORES',
    payload: true,
  });
  api({
    url: 'stores',
    data,
    successHandler: (store) => {
      dispatch(getStores({
        navigate, fromOnboard: true, updateSelectedStore: true, selectedStoreId: store.result.id,
      }));
    },
    errorHandler: (error) => {
      dispatch({
        type: 'SET_LOADING_STORES',
        payload: false,
      });
      dispatch({
        type: 'SET_MESSAGE',
        payload: {
          text: error?.response?.data?.result?.message || 'Hubo un error al crear la tienda',
          kind: 'error',
        },
      });
    },
  });
};

export const setIntegrationStatus = (storeId, integrationId, action) => (dispatch) => {
  dispatch({
    type: 'SET_INTEGRATION_STATUS_LOADING',
    payload: true,
  });
  apiPatch({
    url: `stores/${storeId}/integrations/${integrationId}/${action}`,
    successHandler: () => {
      dispatch(
        getStores({
          updateSelectedStore: true,
          selectedStoreId: storeId,
        }),
      );
      dispatch({
        type: 'SET_INTEGRATION_STATUS_LOADING',
        payload: false,
      });
      dispatch({
        type: 'SET_MESSAGE',
        payload: {
          text: action === 'activate' ? 'Integración activada' : 'Integración desactivada',
          kind: 'success',
        },
      });
    },
    errorHandler: (error) => {
      dispatch({
        type: 'SET_INTEGRATION_STATUS_LOADING',
        payload: false,
      });
      dispatch({
        type: 'SET_MESSAGE',
        payload: {
          text: error?.response?.data?.result?.message || 'Hubo un error al actualizar tu integración',
          kind: 'error',
        },
      });
    },
  });
};

export const getStoreLinks = (storeId) => (dispatch) => {
  dispatch({
    type: 'SET_LOADING_LINKS',
    payload: true,
  });
  apiGet({
    url: `links/${storeId}`,
    successHandler: (data) => {
      dispatch({
        type: 'SET_STORE_LINKS',
        payload: data.result,
      });
      dispatch({
        type: 'SET_LOADING_LINKS',
        payload: false,
      });
    },
    errorHandler: (error) => {
      dispatch({
        type: 'SET_MESSAGE',
        payload: { text: error, kind: 'error' },
      });
      dispatch({
        type: 'SET_LOADING_LINKS',
        payload: false,
      });
    },
  });
};

export const setStoreLogo = (idStore, data, msg, resetInput) => (dispatch) => {
  apiPatch({
    url: `stores/${idStore}`,
    data,
    successHandler: () => {
      resetInput(true);
      dispatch(
        getStores({
          updateSelectedStore: true,
          selectedStoreId: idStore,
        }),
      );
      dispatch({
        type: 'SET_LOADING_STORE_LOGO',
        payload: false,
      });
      dispatch({
        type: 'SET_MESSAGE',
        payload: {
          text: msg.success || 'Store logo updated successfully',
          kind: 'success',
        },
      });
    },
    errorHandler: () => {
      dispatch({
        type: 'SET_MESSAGE',
        payload: {
          text: msg.error || 'Error updating store logo',
          kind: 'error',
        },
      });
      dispatch({
        type: 'SET_LOADING_STORE_LOGO',
        payload: false,
      });
    },
  });
};

export const getPaymentGateways = (idStore, countryName) => (dispatch) => {
  dispatch({
    type: 'SET_LOADING_GATEWAYS',
    payload: true,
  });
  apiGet({
    url: `stores/${idStore}/gateways?country=${countryName}`,
    successHandler: (res) => {
      dispatch({
        type: 'SET_PAYMENT_GATEWAYS',
        payload: res.result,
      });
      dispatch({
        type: 'SET_LOADING_GATEWAYS',
        payload: false,
      });
    },
    errorHandler: () => {
      dispatch({
        type: 'SET_LOADING_GATEWAYS',
        payload: false,
      });
    },
  });
};

export const setGatewayCredentials = (idStore, accountId, gatewayId) => (dispatch) => {
  dispatch({
    type: 'SET_LOADING_GATEWAYS',
    payload: true,
  });
  apiPatch({
    url: `stores/${idStore}/gateways/${gatewayId}`,
    data: {
      credentials: {
        accountId,
      },
    },
    successHandler: () => {
      dispatch(
        getStores({
          updateSelectedStore: true,
          selectedStoreId: idStore,
        }),
      );
      dispatch({
        type: 'SET_MESSAGE',
        payload: { text: 'Id configurado exitosamente', kind: 'success' },
      });
      dispatch({
        type: 'SET_LOADING_GATEWAYS',
        payload: false,
      });
    },
    errorHandler: () => {
      dispatch({
        type: 'SET_MESSAGE',
        payload: { text: 'Hubo un error al configurar el ID de cuenta', kind: 'error' },
      });
      dispatch({
        type: 'SET_LOADING_GATEWAYS',
        payload: false,
      });
    },
  });
};

export const setPaymentGateway = (idStore, gatewayName, gatewayId, action) => (dispatch) => {
  dispatch({
    type: 'SET_LOADING_GATEWAYS',
    payload: true,
  });
  apiPatch({
    url: `stores/${idStore}/gateways/${gatewayId}/${action}`,
    successHandler: () => {
      dispatch(getPaymentGateways(idStore));
      if (action === 'activate') {
        dispatch({
          type: 'SET_MESSAGE',
          payload: { text: `${gatewayName} activado exitosamente`, kind: 'success' },
        });
      } else if (action === 'deactivate') {
        dispatch({
          type: 'SET_MESSAGE',
          payload: { text: `${gatewayName} desactivado exitosamente`, kind: 'success' },
        });
      }
      dispatch({
        type: 'SET_LOADING_GATEWAYS',
        payload: false,
      });
    },
    errorHandler: () => {
      if (action === 'activate') {
        dispatch({
          type: 'SET_MESSAGE',
          payload: { text: `Hubo un error al activar ${gatewayName} en tu tienda`, kind: 'error' },
        });
      } else if (action === 'deactivate') {
        dispatch({
          type: 'SET_MESSAGE',
          payload: { text: `Hubo un error al desactivar ${gatewayName} en tu tienda`, kind: 'error' },
        });
      }
      dispatch({
        type: 'SET_LOADING_GATEWAYS',
        payload: false,
      });
    },
  });
};

export const editStoreConfig = (idStore, data, msg) => (dispatch) => {
  dispatch({
    type: 'SET_LOADING_STORES',
    payload: true,
  });
  apiPatch({
    url: `stores/${idStore}`,
    data,
    successHandler: () => {
      dispatch(
        getStores({
          updateSelectedStore: true,
          selectedStoreId: idStore,
        }),
      );
      dispatch({
        type: 'SET_MESSAGE',
        payload: { text: msg?.success || 'Store info updated', kind: 'success' },
      });
      dispatch({
        type: 'SET_LOADING_STORES',
        payload: false,
      });
    },
    errorHandler: (error) => {
      dispatch({
        type: 'SET_MESSAGE',
        payload: { text: msg?.error || error, kind: 'error' },
      });
      dispatch({
        type: 'SET_LOADING_STORES',
        payload: false,
      });
    },
  });
};

export const setTourData = (idStore, data) => (dispatch) => {
  apiPatch({
    url: `stores/${idStore}`,
    data,
    successHandler: () => {
      dispatch(
        getStores({
          updateSelectedStore: true,
          selectedStoreId: idStore,
        }),
      );
    },
    errorHandler: (error) => {
      dispatch({
        type: 'SET_MESSAGE',
        payload: { text: error, kind: 'error' },
      });
    },
  });
};

export const getTiendaNubeImages = () => (dispatch) => {
  dispatch({
    type: 'SET_LOADING_TN_IMAGES',
    payload: true,
  });
  apiGet({
    url: 'tiendanube/images',
    successHandler: (res) => {
      dispatch({
        type: 'SET_TN_IMAGES',
        payload: res.result,
      });
      dispatch({
        type: 'SET_LOADING_TN_IMAGES',
        payload: false,
      });
    },
    errorHandler: (error) => {
      dispatch({
        type: 'SET_MESSAGE',
        payload: { error, kind: 'error' },
      });
      dispatch({
        type: 'SET_LOADING_TN_IMAGES',
        payload: false,
      });
    },
  });
};

export const setTiendaNubeImage = (storeId, integrationId, data) => (dispatch) => {
  dispatch({
    type: 'SET_LOADING_TN_IMAGES',
    payload: true,
  });
  apiPatch({
    url: `stores/${storeId}/integrations/${integrationId}`,
    data,
    successHandler: () => {
      dispatch(
        getStores({
          updateSelectedStore: true,
          selectedStoreId: storeId,
        }),
      );
      dispatch({
        type: 'SET_MESSAGE',
        payload: { text: 'Apariencia actualizada', kind: 'success' },
      });
      dispatch({
        type: 'SET_LOADING_TN_IMAGES',
        payload: false,
      });
    },
    errorHandler: (error) => {
      dispatch({
        type: 'SET_MESSAGE',
        payload: { error, kind: 'error' },
      });
      dispatch({
        type: 'SET_LOADING_TN_IMAGES',
        payload: false,
      });
    },
  });
};

export const setNewIntegration = (storeId, data, navigate) => (dispatch) => {
  api({
    url: `stores/${storeId}/integrations`,
    data,
    successHandler: (resData) => {
      navigate('/success');
      // getIntegrations(storeId);
      dispatch({
        type: 'SET_INTEGRATION_REDIRECT',
        payload: resData?.result?.redirect,
      });
    },
    errorHandler: (error) => {
      dispatch({
        type: 'SET_MESSAGE',
        payload: { text: error?.response?.data?.result, kind: 'error' },
      });
    },
  });
};

export const setNewWebhook = (storeId, data, msg) => (dispatch) => {
  dispatch({
    type: 'SET_LOADING_WEBHOOK',
    payload: true,
  });
  api({
    url: `stores/${storeId}/integrations`,
    data,
    successHandler: () => {
      dispatch(
        getStores({
          updateSelectedStore: true,
          selectedStoreId: storeId,
        }),
      );
      dispatch({
        type: 'SET_MESSAGE',
        payload: { text: msg?.success || 'Webhook saved', kind: 'success' },
      });
      dispatch({
        type: 'SET_LOADING_WEBHOOK',
        payload: false,
      });
    },
    errorHandler: (error) => {
      dispatch({
        type: 'SET_MESSAGE',
        payload: { text: msg?.error || error, kind: 'error' },
      });
      dispatch({
        type: 'SET_LOADING_WEBHOOK',
        payload: false,
      });
    },
  });
};
