/* eslint-disable no-return-assign */
/* eslint-disable object-shorthand */
/* eslint-disable react/jsx-indent */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box, Grid, Skeleton, Stack, Typography, useMediaQuery,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import Flag from 'react-world-flags';
import { Country } from 'country-state-city';
import { useNavigate } from 'react-router-dom';
import { emailPattern, handleNumericRegex, handleTextRegex } from '../../../utils/regex';
import { SelectInputStyled, TextInputStyled } from '../../StepsCheckout/stepscheckout.styled';
import { PaymentLinkContainer, PaymentLinkForm } from './paymentlink.styled';
import { fCurrency } from '../../../utils/formatNumber';
import { getNationalDocuments } from '../../../redux/actions/nationalDocumentsActions';
import { createPaymentLink } from '../../../redux/actions/paymentsAction';
import PaymentLinkModal from '../../Modals/PaymentLinkModal';
import TransitionComponent from '../TransitionComponent';

function PaymentLink() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(['payment-link']);
  const isMobile = useMediaQuery('(max-width:650px)');
  const countries = Country.getAllCountries();
  const geo = localStorage.getItem('geo');
  // const merchantId = useSelector((state) => state.user?.id_merchant);
  const stores = useSelector((state) => state.merchant.stores);
  const selectedStore = useSelector((state) => state.merchant.selectedStore);
  const storePlan = stores[0]?.plan;
  const loadingDocuments = useSelector((state) => state.paymentLink.loadingDocuments);
  const nationalDocuments = useSelector((state) => state.paymentLink.nationalDocuments);
  const loadingLink = useSelector((state) => state.paymentLink.loadingLink);
  const newLink = useSelector((state) => state.paymentLink.link);

  const [linkModal, setLinkModal] = useState(false);

  const [description, setDescription] = useState('');
  const [amount, setAmount] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [country, setCountry] = useState(geo);
  const [idDocumentType, setIdDocumentType] = useState('');
  const [nationalIdNumber, setNationalIdNumber] = useState('');
  const [redirectLink, setRedirectLink] = useState('');
  const [orderKey, setOrderKey] = useState('');
  const [btnDisabled, setBtnDisabled] = useState(true);

  const [values, setValues] = useState({
    amount: amount,
    description: description,
    user: {
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone_number: phone,
      country: country,
      id_number: nationalIdNumber,
      id_type: idDocumentType,
    },
    redirect_link: redirectLink,
    order_key: orderKey,
  });

  const handleAmount = (value) => {
    if (storePlan?.app_access_level === 1) {
      if (value <= 1000000) {
        setAmount(value);
      }
    } else {
      setAmount(value);
    }
  };

  const createNewLink = (e) => {
    e.preventDefault();
    if (!btnDisabled) {
      dispatch(createPaymentLink(values, selectedStore?.id_store));
    }
  };

  useEffect(() => {
    if (country !== '') {
      dispatch(getNationalDocuments(country));
    }
    if (stores.length === 0) {
      navigate('/');
    }
  }, [country]);

  useEffect(() => {
    if (newLink !== '' && !linkModal) {
      setLinkModal(true);
    } else {
      setLinkModal(false);
    }
  }, [newLink]);

  useEffect(() => {
    if (description !== '' && amount !== '') {
      setBtnDisabled(false);
      setValues({
        amount: amount,
        description: description,
        user: {
          first_name: firstName,
          last_name: lastName,
          email: email,
          phone_number: phone,
          country: country,
          id_number: nationalIdNumber,
          id_type: idDocumentType,
        },
        redirect_link: redirectLink,
        order_key: orderKey,
      });
    } else {
      setBtnDisabled(true);
    }
  }, [
    description,
    amount,
    firstName,
    lastName,
    email,
    phone,
    country,
    idDocumentType,
    nationalIdNumber,
    redirectLink,
    orderKey,
  ]);

  return (
    <TransitionComponent>
      <PaymentLinkContainer>
        <PaymentLinkModal open={linkModal} handleModal={setLinkModal} />
        <PaymentLinkForm>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ paddingBottom: '1.5rem' }}
          >
            <Typography variant="h4">
              {t('payment-link-title')}
            </Typography>
            {selectedStore?.logo_image
              ? (
                <img
                  style={{
                    width: '20%',
                    aspectRatio: '3/1',
                    objectFit: 'contain',
                  }}
                  src={selectedStore?.logo_image}
                  alt="Store logo"
                />
              )
              : (
                <Typography variant="body1">
                  {`(${selectedStore?.store_name})` || ''}
                </Typography>
              )}
          </Stack>
          <Box sx={{ display: 'grid', columnGap: 2, rowGap: 3 }}>
            <TextInputStyled
              type="text"
              label={t('payment-desc-label')}
              placeholder={t('payment-desc-ph')}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
            <TextInputStyled
              type="number"
              label={t('payment-amount-label')}
              placeholder={t('payment-amount-ph')}
              value={amount}
              onChange={(e) => handleAmount(e.target.value)}
              InputLabelProps={{ shrink: true }}
              helperText={storePlan?.app_access_level === 1 && t('paymentlink-max-amount')}
              // FormHelperTextProps={{
              //   component: 'a',
              //   style: {
              //     cursor: 'pointer',
              //   },
              //   onClick: () => window.location.href = 'mailto:soporte@wava.co',
              // }}
              InputProps={{
                inputProps: {
                  min: 0, max: 1000000,
                },
                endAdornment: (
                  <Typography variant="body2" color="text.disabled">
                    {`(${fCurrency(amount)})`}
                  </Typography>
                ),
              }}
            />
            <TextInputStyled
              type="text"
              label={t('payment-redirect-label')}
              placeholder={t('payment-redirect-ph')}
              value={redirectLink}
              onChange={(e) => setRedirectLink(e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
            <TextInputStyled
              type="text"
              label={t('payment-orderkey-label')}
              placeholder={t('payment-orderkey-ph')}
              value={orderKey}
              onChange={(e) => setOrderKey(e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
            <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
              {t('payment-link-user')}
            </Typography>
            <SelectInputStyled
              id="country_code"
              label={t('payment-country-label')}
              placeholder={t('payment-country-ph')}
              select
              required
              value={country}
              SelectProps={{ native: true }}
              InputLabelProps={{ shrink: true, required: false }}
              onChange={(e) => setCountry(e.target.value)}
              InputProps={country ? {
                startAdornment:
                  (<Flag
                    code={country}
                    style={{ marginRight: '0.5rem' }}
                    width="30"
                    fallback=""
                  />),
              } : null}
            >
              <option value="" style={{ display: 'none' }}>
                {t('payment-country-ph')}
              </option>
              {countries.map((c) => (
                <option
                  key={c.isoCode}
                  value={c.isoCode}
                >
                  {c.name}
                </option>
              ))}
            </SelectInputStyled>
            {loadingDocuments
              ? <Skeleton sx={{ height: '3.5rem', borderRadius: '10px' }} variant="rounded" animation="wave" />
              : (nationalDocuments.length > 0
                && (
                  <Grid direction="row" container justifyContent="space-between" spacing={3}>
                    <Grid direction="column" container item xs={12} sm={3}>
                      <SelectInputStyled
                        id="id_national_document_type"
                        label={t('payment-dt-label')}
                        placeholder={t('payment-dt-label')}
                        value={idDocumentType}
                        select
                        InputLabelProps={{ shrink: true, required: false }}
                        SelectProps={{ native: true }}
                        onChange={(e) => setIdDocumentType(e.target.value)}
                        sx={{ width: '100%' }}
                      >
                        <option value="" style={{ display: 'none' }}>
                          {t('payment-dt-label')}
                        </option>
                        {nationalDocuments.map((document) => (
                          <option
                            key={document.type}
                            value={document.id_national_document_type}
                          >
                            {document.type}
                          </option>
                        ))}
                      </SelectInputStyled>
                    </Grid>
                    <Grid direction="column" container item xs={12} sm={9}>
                      {nationalDocuments?.length > 0
                        ? (
                          <TextInputStyled
                            type="text"
                            label={t('payment-dn-label')}
                            placeholder={t('payment-dn-label')}
                            InputLabelProps={{ shrink: true }}
                            id="national_id_number"
                            value={nationalIdNumber}
                            onChange={(e) => setNationalIdNumber(e.target.value)}
                            sx={{ width: '100%' }}
                          />
                        )
                        : (
                          <TextInputStyled
                            name="national_id_number"
                            label={t('id_number_label')}
                            sx={{ width: '100%' }}
                            disabled
                          />
                        )}
                    </Grid>
                  </Grid>
                ))}
            <Grid
              direction={isMobile ? 'column' : 'row'}
              container
              justifyContent="space-between"
              gap={2}
            >
              <TextInputStyled
                type="text"
                label={t('payment-fn-label')}
                placeholder={t('payment-fn-ph')}
                value={firstName}
                onChange={(e) => handleTextRegex(e.target.value, setFirstName)}
                InputLabelProps={{ shrink: true }}
                sx={{ display: 'flex', flex: 1 }}
              />
              <TextInputStyled
                type="text"
                label={t('payment-ln-label')}
                placeholder={t('payment-ln-ph')}
                value={lastName}
                onChange={(e) => handleTextRegex(e.target.value, setLastName)}
                InputLabelProps={{ shrink: true }}
                sx={{ display: 'flex', flex: 1 }}
              />
            </Grid>
            <TextInputStyled
              type="email"
              label={t('payment-e-label')}
              placeholder={t('payment-e-ph')}
              value={email}
              pattern={emailPattern}
              onChange={(e) => setEmail(e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
            <TextInputStyled
              type="text"
              label={t('payment-p-label')}
              placeholder={t('payment-p-ph')}
              value={phone}
              onChange={(e) => handleNumericRegex(e.target.value, setPhone)}
              InputLabelProps={{ shrink: true }}
            />
          </Box>
          <LoadingButton
            size="large"
            type="submit"
            variant="contained"
            color="primary"
            disabled={btnDisabled}
            loading={loadingLink}
            sx={{
              marginTop: '2rem',
              borderRadius: '6rem',
              textTransform: 'none',
            }}
            fullWidth
            onClick={(e) => createNewLink(e)}
          >
            {t('payment-cta-label')}
          </LoadingButton>
        </PaymentLinkForm>
      </PaymentLinkContainer>
    </TransitionComponent>
  );
}

export default PaymentLink;
