/* eslint-disable react/jsx-indent */
import React, { useEffect, useState } from 'react';
import {
  Grid, Stack, Typography, styled, CircularProgress,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Country } from 'country-state-city';
import MenuItem from '@mui/material/MenuItem';
// import Select from '@mui/material/Select';
import Flag from 'react-world-flags';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { ChevronLeft, Upload, Delete } from '@mui/icons-material';
import {
  ButtonStyled, CountrySelect, FormStyled, ReturnButton, StoreLogoContainer, StoreLogoImage, TextInputStyled,
} from '../../Global.styled';
import { createNewStore } from '../../../redux/actions/storesActions';
import { COUNTRIES, availableCountries } from '../../../utils/countries';

export const NewStoreContainer = styled(Grid)(() => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '100%',
  maxHeight: '80vh',
  height: '100%',
  overflow: 'auto',
  gap: '1rem',
  padding: '6rem 6rem 4rem 6rem',
  '@media (max-width: 768px)': {
    width: 'fit-content',
    padding: '4rem 2rem',
    maxHeight: '100%',
    overflow: 'visible',
  },
}));

export const LoadingOverlay = styled('div')(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.7)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000,
}));

function NewStore() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(['login']);
  const integrationsBody = useSelector((state) => state.merchant.integrationsBody);
  const geo = localStorage.getItem('geo');
  const loadingStoreLogo = useSelector((state) => state.merchant.loadingStoreLogo);
  const loadingStores = useSelector((state) => state.merchant.loadingStores);
  const countries = Country.getAllCountries();
  const [selectedCountryCode, setSelectedCountryCode] = useState(geo);
  const [phoneCountry, setPhoneCountry] = useState(geo || 'CO');
  // const phoneDialCode = selectedCountryCode ? COUNTRIES[selectedCountryCode]?.dialCode : '+57';
  const referralCode = useSelector((state) => state.location.referralCode);
  const [storeURLError, setStoreURLError] = useState(false);

  const [formData, setFormData] = useState({
    store_name: '',
    store_url: '',
    country_name: '',
    currency: '',
    store_nit: '',
    phone_number: '',
    referral_code: referralCode || '',
    referred_by: '',
    logo: {
      data: '',
      extension: '',
      mime_type: '',
    },
  });

  const [submitDisabled, setSubmitDisabled] = useState(
    formData.store_name === ''
    || formData.store_url === ''
      || formData.country_name === ''
      || formData.currency === ''
      || formData.store_nit === ''
      || formData.phone_number === '',
  );

  const [imgPreview, setImgPreview] = useState('https://wava-assets.s3.amazonaws.com/wava-dashboard/section-settings/icon-storefront.svg');

  const convertToBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

  const sanitizeInput = (value) => value.replace(/[<>]/g, '');

  const validateFile = (file) => {
    const maxSize = 5 * 1024 * 1024; // 5MB
    const allowedTypes = ['image/jpeg', 'image/png'];

    if (file.size > maxSize) {
      return false;
    }
    if (!allowedTypes.includes(file.type)) {
      return false;
    }
    return true;
  };

  const handleFileChange = async (e) => {
    if (e.target.files && e.target.files[0]) {
      const selectedFile = e.target.files[0];

      if (!validateFile(selectedFile)) {
        console.error('Invalid file type or size');
        return;
      }
      setImgPreview(URL.createObjectURL(selectedFile));
      try {
        const base64 = await convertToBase64(selectedFile);
        const fileExtension = selectedFile.name.split('.').pop().toLowerCase();
        const base64Data = base64.split(',')[1];
        setFormData((prev) => ({
          ...prev,
          logo: {
            data: base64Data,
            extension: fileExtension,
            mime_type: selectedFile.type,
          },
        }));
      } catch (error) {
        console.error('Error converting file to base64:', error);
      }
    }
  };

  const handleCancelChanges = () => {
    document.getElementById('storeLogo').value = '';
    setImgPreview('https://wava-assets.s3.amazonaws.com/wava-dashboard/section-settings/icon-storefront.svg');
    setFormData((prev) => ({
      ...prev,
      logo: {
        data: '',
        extension: '',
        mime_type: '',
      },
    }));
  };

  const [nitLabel, setNitLabel] = useState('NIT/RUT');

  const handlePhoneCountrySelect = (value) => {
    setPhoneCountry(value);
  };

  const handleCountrySelect = (value) => {
    const countryCurrency = countries.find((c) => c.isoCode === value)?.currency;
    setSelectedCountryCode(value);
    setFormData((prev) => ({
      ...prev,
      country_name: countries.find((c) => c.isoCode === value)?.name || '',
      currency: countryCurrency || '',
    }));

    // Update NIT label based on selected country
    switch (value) {
      case 'CO':
        setNitLabel('NIT/RUT');
        break;
      case 'MX':
        setNitLabel('RFC');
        break;
      default:
        setNitLabel('Tax ID');
    }
  };

  const PHONE_REGEX = {
    CO: /^[1-9][0-9]{9}$/, // Colombian numbers: 10 digits, can't start with 0
    MX: /^[1-9][0-9]{9}$/, // Mexican numbers: 10 digits, can't start with 0
    DEFAULT: /^[1-9][0-9]{6,14}$/, // International numbers: 7-15 digits, can't start with 0
  };

  const TAX_ID_REGEX = {
    CO: /^[0-9]{9,10}(-[0-9])?$/, // Colombian NIT: 9-10 digits with optional check digit
    MX: /^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))((-)?([A-Z\d]{3}))?$/, // Mexican RFC
  };

  const [taxIdError, setTaxIdError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);

  const handlePhoneValidation = (value) => {
    if (!value) return false;
    const regex = PHONE_REGEX[phoneCountry] || PHONE_REGEX.DEFAULT;
    return regex.test(value);
  };

  const handleTaxIdValidation = (value) => {
    if (!value) return false;
    const regex = TAX_ID_REGEX[selectedCountryCode];
    return regex ? regex.test(value) : false;
  };

  const handleFormData = (e) => {
    const { name, value } = e.target;
    const sanitizedValue = sanitizeInput(value);
    const hasRegex = e.target?.dataset?.regex && new RegExp(e.target?.dataset?.regex);
    const validRegex = hasRegex?.test(sanitizedValue);

    if (!hasRegex || (hasRegex && validRegex)) {
      if (name === 'phone_number') {
        setPhoneError(!handlePhoneValidation(sanitizedValue));
        setFormData((prev) => ({
          ...prev,
          [name]: sanitizedValue,
        }));
      } else if (name === 'store_nit') {
        const isValid = handleTaxIdValidation(sanitizedValue);
        setTaxIdError(!isValid);
        setFormData((prev) => ({
          ...prev,
          [name]: sanitizedValue,
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          [name]: sanitizedValue,
        }));
      }
    }
  };

  const handleURLValidation = (e) => {
    const sanitizedValue = sanitizeInput(e.target.value);
    const fixedURL = `https://${sanitizedValue}`;

    const urlRegex = /^(https:\/\/)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/;

    if (fixedURL.length > 12) {
      if (urlRegex.test(fixedURL)) {
        setFormData((prev) => ({
          ...prev,
          [e.target.name]: fixedURL,
        }));
        setStoreURLError(false);
      } else {
        setFormData((prev) => ({
          ...prev,
          [e.target.name]: '',
        }));
        setStoreURLError(true);
      }
    } else {
      setFormData((prev) => ({
        ...prev,
        [e.target.name]: '',
      }));
      setStoreURLError(true);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      createNewStore(
        {
          ...formData,
          ...integrationsBody,
        },
        navigate,
      ),
    );
  };

  useEffect(() => {
    if (!formData.country_name) {
      handleCountrySelect(phoneCountry);
    }
    if (
      formData.store_name === ''
      || formData.store_url === ''
      || formData.country_name === ''
      || formData.currency === ''
      || formData.store_nit === ''
      || formData.phone_number === ''
    ) {
      setSubmitDisabled(true);
    } else {
      setSubmitDisabled(false);
    }
  }, [formData]);

  return (
    <NewStoreContainer>
      <ReturnButton onClick={() => navigate(-1)}>
        <ChevronLeft fontSize="inherit" />
        Regresar
      </ReturnButton>
      <Typography variant="h3">{t('Crea una tienda nueva')}</Typography>
      <Typography variant="body1">{t('Regístrate ahora y comienza a crecer con nosotros. ¡Es rápido y fácil!')}</Typography>
      <Typography variant="body1" fontWeight="bold">
        {t('Te tomará menos de 5 minutos')}
      </Typography>
      <FormStyled style={{ marginTop: '1rem', gap: '1.5rem', position: 'relative' }} onSubmit={(e) => handleSubmit(e)}>
        {loadingStores && (
          <LoadingOverlay>
            <CircularProgress />
          </LoadingOverlay>
        )}
        <TextInputStyled
          name="store_name"
          type="text"
          label={t('Nombre de la tienda')}
          required
          disabled={loadingStores}
          helperText={t('Nombre con el que te conocen tus clientes.')}
          onChange={(e) => handleFormData(e)}
          InputLabelProps={{ shrink: true }}
        />
        <TextInputStyled
          name="phone_number"
          type="text"
          label={t('Teléfono')}
          required
          disabled={loadingStores}
          value={formData?.phone_number}
          placeholder={t('0-000-000')}
          helperText={phoneError ? t('Número de teléfono inválido') : t('Número de contacto de Whatsapp de tu tienda.')}
          onChange={(e) => handleFormData(e)}
          InputLabelProps={{ shrink: true }}
          inputMode="numeric"
          InputProps={{
            inputProps: { 'data-regex': '^[0-9]*$' },
            startAdornment: (
              <Grid
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  width: '3rem',
                  marginRight: phoneCountry && COUNTRIES[phoneCountry]?.dialCode ? '3rem' : '0.5rem',
                  gap: '0.5rem',
                }}
              >
                {phoneCountry && <Flag code={phoneCountry} width="30" fallback="" />}
                <CountrySelect name="phone_country" label="phone_country" onChange={(e) => handlePhoneCountrySelect(e.target.value)}>
                  <option label="country_option" value="" style={{ display: 'none' }} />
                  {countries.map((country) => (
                    <option key={country.isoCode} value={country.isoCode}>
                      {country.name}
                    </option>
                  ))}
                </CountrySelect>
                {COUNTRIES[phoneCountry]?.dialCode && <strong>{COUNTRIES[phoneCountry].dialCode}</strong>}
              </Grid>
            ),
          }}
          error={phoneError}
        />
        <TextInputStyled
          name="store_url"
          type="text"
          label={t('Sitio web')}
          required
          disabled={loadingStores}
          error={storeURLError}
          placeholder="ejemplo.com"
          helperText={
            storeURLError ? 'Tu sitio web debe cumplir con la sintaxis correcta. Ej: "https://ejemplo.com"' : t('Dirección web de tu tienda.')
          }
          onChange={(e) => handleURLValidation(e)}
          InputProps={{
            startAdornment: <Typography variant="body1">https://</Typography>,
          }}
          InputLabelProps={{ shrink: true }}
        />

        <TextInputStyled
          select
          name="country_name"
          label={t('Country')}
          required
          disabled={loadingStores}
          value={selectedCountryCode}
          onChange={(e) => handleCountrySelect(e.target.value)}
          InputLabelProps={{ shrink: true }}
        >
          {availableCountries.map((country) => (
            <MenuItem key={country.isoCode} value={country.isoCode}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Flag code={country.isoCode} width="20" fallback="" />
                <span>{country.name}</span>
              </Stack>
            </MenuItem>
          ))}
        </TextInputStyled>

        <TextInputStyled
          name="store_nit"
          type="text"
          value={formData?.store_nit}
          required
          disabled={loadingStores}
          label={t(nitLabel)}
          helperText={taxIdError ? t(`${nitLabel} inválido`) : t('No uses puntos ni comas.')}
          onChange={(e) => handleFormData(e)}
          inputMode="text"
          inputProps={{ 'data-regex': '^[a-zA-Z0-9]*$' }}
          InputLabelProps={{ shrink: true }}
          error={taxIdError}
        />
        <Stack gap={2}>
          <Typography variant="subtitle2" fontWeight="bold">
            Carga el logotipo de tu tienda
          </Typography>
          <Stack direction="row" gap={2} alignItems="center">
            <StoreLogoContainer>
              <StoreLogoImage src={imgPreview} alt="Store logo" />
            </StoreLogoContainer>
            <Stack direction="row" gap={1}>
              <ButtonStyled
                component="label"
                variant="contained"
                startIcon={<Upload />}
                disabled={loadingStores}
                sx={{
                  minWidth: 'unset',
                  padding: '8px',
                  '& .MuiButton-startIcon': {
                    margin: 0,
                  },
                }}
              >
                <input
                  type="file"
                  id="storeLogo"
                  accept="image/*,.png"
                  onChange={(e) => handleFileChange(e)}
                  style={{ display: 'none' }}
                />
              </ButtonStyled>
              {formData.logo.data && (
                <ButtonStyled
                  variant="outlined"
                  disabled={loadingStores}
                  onClick={() => handleCancelChanges()}
                  sx={{ minWidth: 'unset', padding: '8px' }}
                >
                  <Delete />
                </ButtonStyled>
              )}
            </Stack>
          </Stack>
          <Typography variant="caption">
            {t('Te recomendamos que uses imágenes en formato PNG o JPG de mínimo 256×256 píxeles.')}
          </Typography>
        </Stack>
        <LoadingButton
          disabled={submitDisabled || loadingStoreLogo}
          loading={loadingStores}
          type="submit"
          variant="contained"
          size="large"
          sx={{ alignSelf: { sm: 'flex-end' }, borderRadius: '6rem' }}
          onClick={(e) => handleSubmit(e)}
        >
          {t('Siguiente')}
        </LoadingButton>
      </FormStyled>
    </NewStoreContainer>
  );
}

export default NewStore;
