import React from 'react';
import { useTranslation } from 'react-i18next';
// import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { fCurrency } from '../../utils/formatNumber';
// import arrowUp from '../../assets/dashboard/arrow_up_green.svg';
// import arrowDown from '../../assets/dashboard/arrow_down_red.svg';
import CardConversion from '../../assets/dashboard/CardConversion';
import CardRevenue from '../../assets/dashboard/CardRevenue';
import CardTicket from '../../assets/dashboard/CardTicket';
import CardTransactions from '../../assets/dashboard/CardTransactions';
import {
  Carousel, DashCard, DashCardStack, TypographyStyled,
} from './dashboard.styled';

function DashCarousel({ isCompact }) {
  const dashboardData = useSelector((state) => state.dashboard);
  const { t } = useTranslation(['dashboard']);

  return (
    <Carousel
      sx={{
        flexDirection: isCompact ? 'row' : 'row',
        flexWrap: isCompact ? 'wrap' : 'nowrap',
        gap: isCompact ? 1 : 2,
        '& > div': {
          minWidth: isCompact ? 'calc(50% - 4px)' : '280px',
          flex: isCompact ? '0 0 calc(50% - 4px)' : 'initial',
          p: isCompact ? 1.5 : 3,
        },
      }}
    >
      <DashCard>
        <CardRevenue active={dashboardData?.total_revenue} />
        <DashCardStack>
          <TypographyStyled variant="body2" active={dashboardData?.total_revenue ? 1 : 0}>
            {t('card-revenue')}
          </TypographyStyled>
          <TypographyStyled variant="h4" active={dashboardData?.total_revenue ? 1 : 0}>
            {dashboardData?.total_revenue ? `${fCurrency(dashboardData?.total_revenue)}.-` : '-'}
          </TypographyStyled>
        </DashCardStack>
        {/* {dashboardData?.total_revenue
          ? (
            <DashCardChip>
              <img src={arrowUp} alt="" />
              <Typography variant="caption" sx={{ fontWeight: 600, lineHeight: 0 }}>
                37.8%
              </Typography>
              <Typography variant="caption" sx={{ lineHeight: 0, color: '#484848' }}>
                {t('card-week')}
              </Typography>
            </DashCardChip>
          )
          : (
            <TypographyStyled variant="body2">
              {t('card-no-transactions')}
            </TypographyStyled>
          )} */}
        {!dashboardData?.total_revenue && <TypographyStyled variant="body2">{t('card-no-transactions')}</TypographyStyled>}
      </DashCard>
      <DashCard>
        <CardTransactions active={dashboardData?.total_transactions} />
        <DashCardStack>
          <TypographyStyled variant="body2" active={dashboardData?.total_transactions ? 1 : 0}>
            {t('card-transactions')}
          </TypographyStyled>
          <TypographyStyled variant="h4" active={dashboardData?.total_transactions ? 1 : 0}>
            {dashboardData?.total_transactions || '-'}
          </TypographyStyled>
        </DashCardStack>
        {/* {dashboardData?.total_transactions
          ? (
            <DashCardChip>
              <img src={arrowUp} alt="" />
              <Typography variant="caption" sx={{ fontWeight: 600, lineHeight: 0 }}>
                37.8%
              </Typography>
              <Typography variant="caption" sx={{ lineHeight: 0, color: '#484848' }}>
                {t('card-week')}
              </Typography>
            </DashCardChip>
          )
          : (
            <TypographyStyled variant="body2">
              {t('card-no-transactions')}
            </TypographyStyled>
          )} */}
        {!dashboardData?.total_transactions && <TypographyStyled variant="body2">{t('card-no-transactions')}</TypographyStyled>}
      </DashCard>
      <DashCard>
        <CardTicket active={dashboardData?.avg_ticket} />
        <DashCardStack>
          <TypographyStyled variant="body2" active={dashboardData?.avg_ticket ? 1 : 0}>
            {t('card-ticket')}
          </TypographyStyled>
          <TypographyStyled variant="h4" active={dashboardData?.avg_ticket ? 1 : 0}>
            {dashboardData?.avg_ticket ? `${fCurrency(dashboardData?.avg_ticket)}.-` : '-'}
          </TypographyStyled>
        </DashCardStack>
        {/* {dashboardData?.avg_ticket
          ? (
            <DashCardChip>
              <img src={arrowDown} alt="" />
              <Typography variant="caption" sx={{ fontWeight: 600, lineHeight: 0 }}>
                -0.8%
              </Typography>
              <Typography variant="caption" sx={{ lineHeight: 0, color: '#484848' }}>
                {t('card-week')}
              </Typography>
            </DashCardChip>
          )
          : (
            <TypographyStyled variant="body2">
              {t('card-no-transactions')}
            </TypographyStyled>
          )} */}
        {!dashboardData?.avg_ticket && <TypographyStyled variant="body2">{t('card-no-transactions')}</TypographyStyled>}
      </DashCard>
      <DashCard>
        <CardConversion active={dashboardData?.cr} />
        <DashCardStack>
          <TypographyStyled variant="body2" active={dashboardData?.cr ? 1 : 0}>
            {t('card-conversion')}
          </TypographyStyled>
          <TypographyStyled variant="h4" active={dashboardData?.cr ? 1 : 0}>
            {dashboardData?.cr ? `${dashboardData?.cr}%` : '-'}
          </TypographyStyled>
        </DashCardStack>
        {/* {dashboardData?.cr
          ? (
            <DashCardChip>
              <img src={arrowDown} alt="" />
              <Typography variant="caption" sx={{ fontWeight: 600, lineHeight: 0 }}>
                -0.8%
              </Typography>
              <Typography variant="caption" sx={{ lineHeight: 0, color: '#484848' }}>
                {t('card-week')}
              </Typography>
            </DashCardChip>
          )
          : (
            <TypographyStyled variant="body2">
              {t('card-no-transactions')}
            </TypographyStyled>
          )} */}
        {!dashboardData?.cr && <TypographyStyled variant="body2">{t('card-no-transactions')}</TypographyStyled>}
      </DashCard>
    </Carousel>
  );
}

DashCarousel.defaultProps = {
  isCompact: false,
};

export default DashCarousel;
