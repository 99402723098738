import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import { useTranslation } from 'react-i18next';

function LinksTableHead(props) {
  const { t } = useTranslation(['payment-link']);
  const {
    order, orderBy, onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const headCells = [
    {
      id: 'description',
      numeric: false,
      disablePadding: true,
      label: t('links-list-description'),
    },
    {
      id: 'amount',
      numeric: true,
      disablePadding: false,
      label: t('links-list-amount'),
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: false,
      label: t('links-list-email'),
    },
    {
      id: 'order_key',
      numeric: false,
      disablePadding: false,
      label: t('links-list-order'),
    },
    {
      id: 'url',
      numeric: false,
      disablePadding: false,
      label: t('links-list-url'),
    },
    {
      id: 'created',
      numeric: true,
      disablePadding: false,
      label: t('links-list-date'),
    },
  ];

  return (
    <TableHead>
      <TableRow>
        <TableCell>
          {}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ maxWidth: '10rem' }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

LinksTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default LinksTableHead;
