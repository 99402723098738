import { apiGet, apiPatch, api } from '../../utils/api';

export const getAdminStores = (filter = '') =>
  (dispatch) => {
    dispatch({
      type: 'SET_LOADING_ADMIN_STORES',
      payload: true,
    });
    apiGet({
      url: `stores/admin?${filter}`,
      successHandler: (data) => {
        dispatch({
          type: 'SET_ADMIN_STORES',
          payload: data.result,
        });
        dispatch({
          type: 'SET_LOADING_ADMIN_STORES',
          payload: false,
        });
      },
      errorHandler: (error) => {
        dispatch({
          type: 'SET_LOADING_ADMIN_STORES',
          payload: false,
        });
        dispatch({
          type: 'SET_MESSAGE',
          payload: { text: error, kind: 'error' },
        });
      },
    });
  };

export const getAdminStoreDetails = (storeId) => async (dispatch) => {
  dispatch({ type: 'FETCH_STORE_DETAILS_LOADING', payload: true });
  apiGet({
    url: `stores/${storeId}`,
    successHandler: (data) => {
      dispatch({
        type: 'FETCH_STORE_DETAILS_SUCCESS',
        payload: data.result,
      });
      dispatch({ type: 'FETCH_STORE_DETAILS_LOADING', payload: false });
    },
    errorHandler: (error) => {
      dispatch({
        type: 'FETCH_STORE_DETAILS_FAILURE',
        payload: error?.message,
      });
      dispatch({ type: 'FETCH_STORE_DETAILS_LOADING', payload: false });
    },
  });
};

export const searchUserByEmail = (email) => async (dispatch) => {
  try {
    dispatch({ type: 'SET_LOADING', payload: true });
    apiGet({
      url: `users/all?email=${email}`,
      successHandler: (data) => {
        dispatch({ type: 'SET_USER_SEARCH_RESULTS', payload: data.result });
      },
      errorHandler: (error) => {
        throw new Error(error.message || 'Error searching user');
      },
    });
  } catch (error) {
    dispatch({ type: 'SET_MESSAGE', payload: { text: error.message, kind: 'error' } });
    return null;
  } finally {
    dispatch({ type: 'SET_LOADING', payload: false });
  }
};

export const addStoreMerchant = (storeId, userId) => async (dispatch) => {
  try {
    dispatch({ type: 'SET_LOADING', payload: true });
    api({
      url: `stores/${storeId}/merchants/${userId}`,
      successHandler: (data) => {
        return data.data;
      },
      errorHandler: (error) => {
        throw new Error(error.message || 'Error adding merchant');
      },
    });
    dispatch(getAdminStoreDetails(storeId));
    return true;
  } catch (error) {
    dispatch({ type: 'SET_MESSAGE', payload: { text: error.message, kind: 'error' } });
    return false;
  } finally {
    dispatch({ type: 'SET_LOADING', payload: false });
  }
};

export const updateStore = (storeId, data) => async (dispatch) => {
  try {
    dispatch({ type: 'SET_LOADING', payload: true });
    await apiPatch({
      url: `stores/${storeId}`,
      data,
      successHandler: (response) => {
        return response.data;
      },
      errorHandler: (error) => {
        throw new Error(error?.message || 'Error updating store');
      },
    });
    dispatch(getAdminStoreDetails(storeId));
    return true;
  } catch (error) {
    dispatch({ type: 'SET_MESSAGE', payload: { text: error?.message, kind: 'error' } });
    return false;
  } finally {
    dispatch({ type: 'SET_LOADING', payload: false });
  }
};
