import React, { useEffect, useState } from 'react';
import { FilloutStandardEmbed } from '@fillout/react';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setNewIntegration, getStore } from '../../../redux/actions/storesActions';

function FilloutForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stores = useSelector((state) => state.merchant.stores);
  const selectedStore = useSelector((state) => state.merchant.selectedStore);
  const user = useSelector((state) => state.user);
  const integrationsBody = useSelector((state) => state.merchant.integrationsBody);
  const locationPath = useSelector((state) => state.location.path);
  const onboardCode = useSelector((state) => state.location.onboardCode);
  const fromPlatform = useSelector((state) => state.platform);
  const [initialTimer, setInitialTimer] = useState(60);
  const [secondTimer, setSecondTimer] = useState(15);

  const getFilloutId = () => {
    const country = selectedStore?.country_name === 'Mexico' ? 'MX' : 'CO';
    if (!onboardCode && !fromPlatform) {
      return country === 'MX'
        ? process.env.REACT_APP_FILLOUT_MX_GENERIC
        : process.env.REACT_APP_FILLOUT_CO_GENERIC;
    }

    if (onboardCode && fromPlatform !== 'woocommerce') {
      return country === 'MX'
        ? process.env.REACT_APP_FILLOUT_MX_TIENDANUBE
        : process.env.REACT_APP_FILLOUT_CO_TIENDANUBE;
    }

    if (fromPlatform === 'woocommerce' && !onboardCode) {
      return country === 'MX'
        ? process.env.REACT_APP_FILLOUT_MX_WOO
        : process.env.REACT_APP_FILLOUT_CO_WOO;
    }

    // Default fallback
    return country === 'MX'
      ? process.env.REACT_APP_FILLOUT_MX_GENERIC
      : process.env.REACT_APP_FILLOUT_CO_GENERIC;
  };

  useEffect(() => {
    if (locationPath === '/onboard') {
      dispatch({ type: 'RESET_LOCATION' });
    }
  }, []);

  useEffect(() => {
    const intId = setInterval(() => {
      if (selectedStore.status === 'draft') {
        if (initialTimer > 0) {
          setInitialTimer(initialTimer - 1);
        }
        if (initialTimer === 0 && secondTimer === 15) {
          console.log('draftStore', selectedStore);
          dispatch(getStore({ storeId: selectedStore.id_store, fromOnboard: true }));
        }
        if (initialTimer === 0 && secondTimer > 0) {
          setSecondTimer(secondTimer - 1);
        }
        if (secondTimer === 0) {
          setSecondTimer(15);
        }
      } else {
        // Clear the interval before navigating or dispatching
        clearInterval(intId);
        if (!fromPlatform) {
          navigate('/success');
        } else {
          dispatch(setNewIntegration(stores[stores.length - 1]?.id_store, integrationsBody, navigate));
        }
      }
    }, 1000);

    return () => {
      clearInterval(intId);
    };
  }, [initialTimer, secondTimer]);

  return (
    <Grid width="100%" minHeight="100%">
      <FilloutStandardEmbed
        filloutId={getFilloutId()}
        parameters={{
          id_store: selectedStore?.id_store,
          merchant: selectedStore?.store_name,
          phone: selectedStore?.phone_number,
          website: selectedStore?.store_url,
          rut: selectedStore?.store_nit,
          email: user?.email,
          platform: fromPlatform,
        }}
      />
    </Grid>
  );
}

export default FilloutForm;
