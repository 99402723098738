import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import MenuPopover from '../../MenuPopover/MenuPopover';
import { IconButtonStyled, MenuItemStyled } from './navpopover.styled';

function NavPopover() {
  const { t } = useTranslation(['dashboard']);
  const navigate = useNavigate();
  const [open, setOpen] = useState(null);
  const isMobile = useMediaQuery('(max-width:900px)');

  const isMerchant = true; // user.id_merchant !== null;

  const handleNavigation = (path) => {
    setOpen(null);
    if (path) {
      navigate(path);
    }
  };

  return (
    <>
      <IconButtonStyled onClick={(e) => setOpen(e.currentTarget)}>
        <MenuIcon />
      </IconButtonStyled>
      <MenuPopover arrow="top-left" open={isMobile ? Boolean(open) : false} anchorEl={open} onClose={() => setOpen(null)} sx={{ p: 0 }}>
        <MenuItemStyled show={isMerchant ? 1 : 0} onClick={() => handleNavigation('/dashboard')}>
          {t('navigation-dashboard')}
        </MenuItemStyled>
        <MenuItemStyled show={isMerchant ? 1 : 0} onClick={() => handleNavigation('/paymentLink')}>
          {t('navigation-payment-link')}
        </MenuItemStyled>
        <MenuItemStyled show={isMerchant ? 1 : 0} onClick={() => handleNavigation('/storeLinks')}>
          {t('navigation-store-links')}
        </MenuItemStyled>
        <MenuItemStyled show={isMerchant ? 0 : 1} onClick={() => handleNavigation('/profile')}>
          {t('navigation-profile')}
        </MenuItemStyled>
        <MenuItemStyled show={1} onClick={() => handleNavigation('/orders')}>
          {t('navigation-orders')}
        </MenuItemStyled>
        <MenuItemStyled show={isMerchant ? 1 : 0} onClick={() => handleNavigation('/config')}>
          {t('navigation-config')}
        </MenuItemStyled>
        <MenuItemStyled
          show={isMerchant ? 1 : 0}
          onClick={() => window.open('https://www.notion.so/wava/3d91f531f641426fa4817d84689855ff?v=b852bf9938014ab09b6b2c243a5eb3aa', '_blank')}
        >
          {t('navigation-docs')}
        </MenuItemStyled>
      </MenuPopover>
    </>
  );
}

export default NavPopover;
